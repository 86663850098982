import { useEffect, useState } from 'react';
import { eventService } from '../../services/event.service';
import { EventType, UseEventsType } from './types';
import { useFiltersState } from '../../store/filters/context';

const useEvents = (): UseEventsType => {
  const { season } = useFiltersState();
  const [events, setEvents] = useState<EventType[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getEvents = async () => {
      setLoading(true);
      setError(false);
      try {
        const response = await eventService.getEvents({
          season,
        });
        setEvents(response.data);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getEvents();
  }, [season]);

  return { events, loading, error };
};

export default useEvents;
