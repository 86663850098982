import React, { FC, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { useFilters } from '../../../store/filters/useFilters';
import Navbar from '../../navBar';
import TeamDetails from '../../teamDetails';
import PitStopTable from '../../pitStopTable';
import ResultsTable from '../../resultsTable';

interface TeamParams {
  teamId: string;
}

export interface TeamProps extends RouteComponentProps<TeamParams> {}

const TeamPage: FC<TeamProps> = ({ match: { params } }) => {
  const { setTeam, setFilterTeam, setFilterSession } = useFilters();

  useEffect(() => {
    setTeam(parseInt(params.teamId));
    setFilterTeam(true);
    setFilterSession(false);
    // eslint-disable-next-line
  }, [params.teamId]);

  return (
    <div className="container">
      <Navbar />
      <div className="row">
        <TeamDetails />
      </div>
      <hr></hr>
      <div className="row">
        <div className="col-sm-12">
          <h5>Latest Results</h5>
        </div>
        <ResultsTable />
      </div>
      <hr></hr>
      <div className="row">
        <div className="col-sm-12">
          <h5>Fastest Pit Stops</h5>
        </div>
        <PitStopTable />
      </div>
    </div>
  );
};

export default withRouter(TeamPage);
