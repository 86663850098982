import React, { FC, useContext } from 'react';
import { Grid } from '@visx/grid';
import { Group } from '@visx/group';
import { ChartContext } from '../chartProvider';
import { AxisLeft, AxisBottom } from '@visx/axis';
import * as constants from '../../../constants/chart.constants';

import styles from './chartLayout.module.scss';

export interface ChartLayoutProps {}

const ChartLayout: FC<ChartLayoutProps> = () => {
  const { xLabel, yLabel, xScale, yScale, margins, xMax, yMax } = useContext(ChartContext);

  return (
    <Group left={margins?.left} top={margins?.top}>
      {xScale && yScale && xMax && yMax && (
        <Grid
          xScale={xScale}
          yScale={yScale}
          width={xMax}
          height={yMax}
          pointerEvents={'none'}
          className={styles.grid}
          numTicksRows={constants.YNUMBEROFTICKS}
          numTicksColumns={constants.XNUMBEROFTICKS}
          strokeDasharray={constants.GRIDSTROKEDASHARRAY}
        />
      )}
      {xScale && xMax && (
        <AxisBottom
          scale={xScale}
          top={yMax}
          label={xLabel}
          axisClassName={styles.axis}
          tickClassName={styles.tick}
          numTicks={constants.XNUMBEROFTICKS}
          labelClassName={`${styles.label} ${styles.bottom}`}
        />
      )}
      {yScale && (
        <AxisLeft
          scale={yScale}
          label={yLabel}
          axisClassName={styles.axis}
          labelOffset={constants.LABELOFFSET}
          numTicks={constants.YNUMBEROFTICKS}
          labelClassName={`${styles.label} ${styles.left}`}
          tickClassName={`${styles.tick} ${styles.left}`}
        />
      )}
    </Group>
  );
};

export default ChartLayout;
