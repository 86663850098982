import React, { FC, useContext } from 'react';
import ChartBaseBar from '../chartBaseBar';
import { animated } from 'react-spring';
import { ChartContext } from '../chartProvider';
import { Point } from '../chart';
import { getX, getY } from '../../../utils/chart';

interface AnimatedChartBarProps {
  id: string;
  data: Point;
  stroke?: string;
}

const AnimatedChartBar: FC<AnimatedChartBarProps> = ({ id, data, stroke }) => {
  const { xScale, yScale, margins } = useContext(ChartContext);

  const [yDomainMin] = yScale && (yScale.domain() as any);

  const x = xScale && xScale(getX(data));
  const y = yScale && yScale(getY(data) > 0 ? getY(data) : 0);
  const width = xScale && 'bandwidth' in xScale ? xScale?.bandwidth() : 0;
  const height = yScale
    ? Math.abs(yScale(getY(data)) - (getY(data) > 0 ? yScale(Math.max(0, yDomainMin)) : yScale(0)))
    : 0;

  return <ChartBaseBar id={id} x={x} y={y} width={width} height={height} stroke={stroke} margins={margins} />;
};

export default animated(AnimatedChartBar);
