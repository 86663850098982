import { useEffect } from 'react';
import useEvents from '../../hooks/event/useEvents';
import useSessions from '../../hooks/session/useSessions';
import useDrivers from '../../hooks/driver/useDrivers';
import { useGlobal } from './useGlobal';
import useColours from '../../hooks/colour/useColours';

const useInitGlobal = () => {
  const { setEvents, setSessions, setDrivers, setColours } = useGlobal();

  const { events } = useEvents();
  const { drivers } = useDrivers();
  const { sessions } = useSessions();
  const { colours } = useColours();

  useEffect(() => setEvents(events), [events, setEvents]);
  useEffect(() => setDrivers(drivers), [drivers, setDrivers]);
  useEffect(() => setSessions(sessions), [sessions, setSessions]);
  useEffect(() => setColours(colours), [colours, setColours]);
};

export { useInitGlobal };
