import { useEffect } from 'react';
import { UseChartEventHandlerType } from './types';

const useChartEventHandler = ({ onKeyDown, onKeyUp }: UseChartEventHandlerType): UseChartEventHandlerType => {
  useEffect(() => {
    onKeyDown && document.addEventListener('keydown', onKeyDown);
    onKeyUp && document.addEventListener('keyup', onKeyUp);

    return () => {
      onKeyDown && document.removeEventListener('keydown', onKeyDown);
      onKeyUp && document.removeEventListener('keyup', onKeyUp);
    };
  }, [onKeyDown, onKeyUp]);

  return { onKeyDown, onKeyUp };
};

export default useChartEventHandler;
