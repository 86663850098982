import { useEffect, useState } from 'react';
import { colourService } from '../../services/colour.service';
import { ColourType, UseColoursType } from './types';
import { useFiltersState } from '../../store/filters/context';

const useColours = (): UseColoursType => {
  const { season } = useFiltersState();
  const [colours, setColours] = useState<ColourType[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getColours = async () => {
      setLoading(true);
      setError(false);
      try {
        const response = await colourService.getColours({
          season,
        });
        setColours(response.data);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getColours();
  }, [season]);

  return { colours, loading, error };
};

export default useColours;
