import React, { FC, useEffect, ElementType } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useLogin } from '../../../store/auth/useLogin';
import PreloadPage from '../../pages/preloadPage';

interface ProtectedRouteProps {
  exact?: boolean;
  path: string;
  redirect?: string;
  component: ElementType;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ exact, path, redirect, component: Component, ...rest }) => {
  const { auth, getUser } = useLogin();
  let location = useLocation();

  const ValidateUser = () => {
    useEffect(() => {
      if (!auth.user) {
        getUser();
      }
    }, []);
  };

  ValidateUser();

  return (
    <Route
      exact={exact}
      path={path}
      {...rest}
      render={(props) => {
        if (auth.renewError) {
          return <Redirect to={{ pathname: '/login', state: { from: location.pathname } }} />;
        } else if (redirect) {
          return <Redirect to={{ pathname: redirect, state: { from: location.pathname } }} />;
        } else if (!auth.user) {
          return <PreloadPage></PreloadPage>;
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
