import { useEffect, useState } from 'react';
import { headToHeadService } from '../../services/headToHead.service';
import { HeadToHead, UseHeadToHeadType } from './types';

const useHeadToHead = (sessionId?: number, driver1?: number, driver2?: number): UseHeadToHeadType => {
  const [headToHead, setHeadToHead] = useState<HeadToHead[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getHeadToHead = async () => {
      if (!sessionId || !driver1 || !driver2) return;

      setLoading(true);
      setError(false);
      try {
        const response = await headToHeadService.getHeadToHead({
          session_id: sessionId,
          driver1: driver1,
          driver2: driver2,
        });
        if (response) {
          setHeadToHead(response.data);
        }
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getHeadToHead();
  }, [sessionId, driver1, driver2]);

  return { headToHead, loading, error };
};

export default useHeadToHead;
