import React, { FC } from 'react';
import { LapType } from '../../hooks/lap/types';
import Table from '../common/table';
import './timingPageOneTable.css';

interface TimingPageOneTableProps {
  laps: LapType[];
}

const TimingPageOneTable: FC<TimingPageOneTableProps> = ({ laps }) => {
  return (
    <div className="col-sm-12">
      <Table
        tableClass={`text-center timing`}
        headings={['Pos', '#', 'Name', 'LapTime', 'Gap', 'Int', 'S1', 'S2', 'S3']}
        rows={laps.map((lap) => {
          return [
            lap.race_position,
            lap.driver_number,
            lap.driver_code,
            lap.lap_time ? new Date(lap.lap_time * 1000).toISOString().substr(15, 8) : '',
            lap.gap || '',
            lap.interval || '',
            lap.sector_times[0] ? new Date(lap.sector_times[0] * 1000).toISOString().substr(17, 6) : '',
            lap.sector_times[1] ? new Date(lap.sector_times[1] * 1000).toISOString().substr(17, 6) : '',
            lap.sector_times[2] ? new Date(lap.sector_times[2] * 1000).toISOString().substr(17, 6) : '',
          ].map(String);
        })}
        rowsClass={laps.map((lap) => {
          return [
            'position',
            '',
            '',
            lap.lap_time_state || 'OLD',
            '',
            '',
            lap.sector_time_states[0] || 'OLD',
            lap.sector_time_states[1] || 'OLD',
            lap.sector_time_states[2] || 'OLD',
          ].map(String);
        })}
      />
    </div>
  );
};

export default TimingPageOneTable;
