import React, { FC } from 'react';
import AutoComplete, { AutoCompleteProps } from '../common/autoComplete';

import styles from './driverAutoComplete.module.scss';

export type DriverAutoCompleteProps = AutoCompleteProps;

const DriverAutoComplete: FC<DriverAutoCompleteProps> = ({ classes, ...props }) => {
  return (
    <AutoComplete
      classes={`${styles.container} ${classes}`}
      inputClasses={styles.inputClasses}
      inputInputClasses={styles.inputInputClasses}
      inputLabelClasses={styles.inputLabelClasses}
      inputErrorClasses={styles.inputErrorClasses}
      dropdownClasses={styles.dropdownClasses}
      {...props}
    />
  );
};

export default DriverAutoComplete;
