import { useEffect, useState } from 'react';
import { sessionService } from '../../services/session.service';
import { SessionType, UseSessionsType } from './types';
import { useFiltersState } from '../../store/filters/context';

const useSessions = (): UseSessionsType => {
  const { event_id } = useFiltersState();
  const [sessions, setSessions] = useState<SessionType[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getSessions = async () => {
      setLoading(true);
      setError(false);
      try {
        const response = await sessionService.getSessions({
          event_id,
        });
        setSessions(response.data);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getSessions();
  }, [event_id]);

  return { sessions, loading, error };
};

export default useSessions;
