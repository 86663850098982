import React, { FC, FormEvent } from 'react';
import { useFilters } from '../../store/filters/useFilters';
import { useGlobal } from '../../store/global/useGlobal';
import Dropdown from '../common/dropdown';

export interface SessionsFilterProps {}

const SessionsFilter: FC<SessionsFilterProps> = () => {
  const { session_id, setSession } = useFilters();
  const { sessions } = useGlobal();

  const handleClick = (event: FormEvent<EventTarget>) => {
    const { id: target } = event.currentTarget as Element;
    const element_id = sessions.find((session) => session.type === target)?.id;
    if (element_id) {
      setSession(element_id);
    }
  };

  return (
    <div className="col-sm-12 col-md-4 mb-2">
      <Dropdown
        title="Session"
        items={sessions.map((session) => {
          return session.type;
        })}
        active={sessions.find((session) => session.id === session_id)?.type}
        className="btn-block"
        onClick={handleClick}
      />
    </div>
  );
};

export default SessionsFilter;
