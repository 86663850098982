import React, { createContext, useReducer, useContext } from 'react';
import { Dispatch, State, FiltersProviderProps } from './types';
import { history } from '../../utils/history';
import filtersReducer from './reducer';
import { url } from 'web-common';

const FiltersStateContext = createContext<State | undefined>(undefined);
const FiltersDispatchContext = createContext<Dispatch | undefined>(undefined);

const FiltersProvider = ({ children }: FiltersProviderProps) => {
  const urlParams = url.paramsToObject(history.location.search);

  const [state, dispatch] = useReducer(
    filtersReducer,
    Object.keys(urlParams).length
      ? urlParams
      : {
          season: 2020,
          event_id: 3,
          session_id: 1,
          filter_session: true,
          team_id: 1,
          filter_team: false,
        }
  );
  return (
    <FiltersStateContext.Provider value={state}>
      <FiltersDispatchContext.Provider value={dispatch}>{children}</FiltersDispatchContext.Provider>
    </FiltersStateContext.Provider>
  );
};

const useFiltersState = () => {
  const context = useContext(FiltersStateContext);
  if (context === undefined) {
    throw new Error('useFiltersState must be used within a FiltersProvider');
  }
  return context;
};

const useFiltersDispatch = () => {
  const context = useContext(FiltersDispatchContext);
  if (context === undefined) {
    throw new Error('useFiltersDispatch must be used within a FiltersProvider');
  }
  return context;
};

export { FiltersProvider, useFiltersState, useFiltersDispatch };
