import React, { FC, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import * as constants from '../../../constants/resources.constants';
import { LapType } from '../../../hooks/lap/types';
import Navbar from '../../navBar';
import LiveSessionHeader from '../../liveSessionHeader';
import TimingPageOneTable from '../../timingPageOneTable';

interface LiveParams {}

export interface LiveProps extends RouteComponentProps<LiveParams> {}

// const ws = new WebSocket(constants.WEBSOCKET_URL || '');

const LivePage: FC<LiveProps> = () => {
  const [timing, setTiming] = useState<LapType[]>([]);

  // ws.onmessage = (event: MessageEvent) => {
  //   const data = JSON.parse(event.data);
  //   setTiming(data);
  // };

  return (
    <div className="container">
      <Navbar />
      <div className="row mt-4">
        <LiveSessionHeader />
      </div>
      <div className="row mt-4">
        <TimingPageOneTable laps={timing} />
      </div>
    </div>
  );
};

export default withRouter(LivePage);
