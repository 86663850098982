import * as types from './types';

const filtersReducer = (state: types.State, action: types.Action) => {
  switch (action.type) {
    case types.SET_SEASON: {
      return { ...state, season: action.season };
    }
    case types.SET_EVENT: {
      return { ...state, event_id: action.event_id };
    }
    case types.SET_SESSION: {
      return { ...state, session_id: action.session_id };
    }
    case types.SET_FILTER_SESSION: {
      return { ...state, filter_session: action.filter_session };
    }
    case types.SET_TEAM: {
      return { ...state, team_id: action.team_id };
    }
    case types.SET_FILTER_TEAM: {
      return { ...state, filter_team: action.filter_team };
    }
    case types.CLEAR_FILTERS: {
      return {};
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

export default filtersReducer;
