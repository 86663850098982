import React, { FC } from 'react';
import { useFilters } from '../../store/filters/useFilters';
import useTeam from '../../hooks/team/useTeam';
import Spinner from '../common/spinner';

export interface TeamDetailsProps {}

const TeamDetails: FC<TeamDetailsProps> = () => {
  const { team_id } = useFilters();
  const { team } = useTeam(team_id ? team_id.toString() : '');

  if (!team) {
    return <Spinner />;
  }

  return (
    <div className="col-sm-12">
      <h2>{`${team?.name}`}</h2>
    </div>
  );
};

export default TeamDetails;
