import React, { FC } from 'react';
import usePitStops from '../../hooks/pitStop/usePitStops';
import Table from '../common/table';

export interface PitStopsTableProps {}

const PitStopTable: FC<PitStopsTableProps> = () => {
  const { pitStops } = usePitStops();

  return (
    <div className="col-sm-12">
      <Table
        tableClass={`text-center`}
        headings={['No.', 'Driver', 'Team', 'Lap', 'Duration (s)']}
        rows={pitStops.map((pitStop) => {
          return [
            pitStop.driver_number,
            `${pitStop.driver_forename} ${pitStop.driver_surname}`,
            pitStop.team_name,
            pitStop.lap_number,
            pitStop.duration / 1000.0,
          ].map(String);
        })}
      />
    </div>
  );
};

export default PitStopTable;
