import http from './http.services';
import * as constants from '../constants/resources.constants';
import { LapsType, LapType } from '../hooks/lap/types';

const getLaps = async (filters?: object): Promise<LapsType> => {
  const { data } = await http.get(constants.LAPS, {
    withCredentials: true,
    params: {
      ...filters,
    },
  });
  return data;
};

const getLap = async (id: string): Promise<LapType> => {
  const { data } = await http.get(constants.LAP.replace('{id}', id), {
    withCredentials: true,
  });
  return data.data;
};

const getTimingPageOne = async (): Promise<LapsType> => {
  const { data } = await http.get(constants.TIMING_PAGE_ONE, {
    withCredentials: true,
  });
  return data;
};

export const lapService = {
  getLaps,
  getLap,
  getTimingPageOne,
};
