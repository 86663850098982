import React, { FC, FormEvent, ReactNode } from 'react';

interface TableProps {
  headings: string[];
  rows: (string | number | ReactNode)[][];
  tableClass?: string;
  rowsClass?: string[][];
  onClick?: (() => void) | ((event: FormEvent<EventTarget>) => void);
}

const Table: FC<TableProps> = ({ headings, rows, tableClass, rowsClass, onClick }) => {
  return (
    <table className={`table table-dark table-bordered table-hover table-sm text-center ${tableClass}`}>
      <thead>
        <tr>
          {headings.map((heading, index) => (
            <th scope="col" key={index}>
              {heading}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, row_index) => (
          <tr key={row_index} onClick={onClick}>
            {row.map((cell, cell_index) => (
              <td className={rowsClass ? rowsClass[row_index][cell_index] : ''} key={cell_index}>
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
