import React, { FC, useState, FormEvent } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { useFilters } from '../../../store/filters/useFilters';
import Navbar from '../../navBar';
import { msToSeconds } from '../../../utils/time';
import SeasonsFilter from '../../seasonsFilter';
import EventsFilter from '../../eventsFilter';
import SessionsFilter from '../../sessionsFilter';
import SessionHeader from '../../sessionHeader';
import ResultsTable from '../../resultsTable';
import ResultsNavTab from '../../resultsNavTab';
import PitStopList from '../../pitStopList';
import OvertakeList from '../../overtakeList';
import LapsTable from '../../lapsTable';
import LapsLineChart from '../../lapsLineChart';
import PlayerModal from '../../playerModal';
import HeadToHeadChart from '../../headToHeadChart';
import useVideo from '../../../hooks/video/useVideo';
import { Driver, Event, Season, Session } from '../../../hooks/video/types';
import * as constants from '../../../constants/global.constants';
import { useGlobal } from '../../../store/global/useGlobal';
import withGlobal from '../../common/withGlobal';

import styles from './resultPage.module.scss';

interface ResultParams {
  season: string;
  eventId: string;
  sessionId: string;
}

export interface ResultProps extends RouteComponentProps<ResultParams> {}

const ResultPage: FC<ResultProps> = ({ match: { params } }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [driverId, setDriverId] = useState<number>();
  const [time, setTime] = useState<{ startTime?: number; endTime?: number }>();

  const { season, event_id, session_id } = useFilters();
  const { getEvent, getSession, getDriver } = useGlobal();

  const seasonName = season?.toString() as Season;
  const eventName = (event_id ? getEvent(event_id)?.short_name : '') as Event;
  const sessionName = (session_id ? getSession(session_id)?.type : '') as Session;
  const driverName = (driverId ? getDriver(driverId)?.surname : '') as Driver;

  const { video: { signedLink = '', startSourceTime = 0, endSourceTime = 0 } = {} } = useVideo(
    eventName,
    sessionName,
    constants.ONBOARD,
    seasonName,
    driverName
  );

  const handleOpenModal = (startTime: number, endTime: number) => {
    setTime({
      startTime: msToSeconds(startTime - startSourceTime),
      endTime: msToSeconds(endTime - startSourceTime),
    });
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setTime({});
    setIsOpen(false);
  };

  enum Display {
    RESULTS = 'Results',
    LAPS = 'Laps',
    CHARTS = 'Charts',
    PIT_STOPS = 'PitStops',
    OVERTAKES = 'Overtakes',
  }
  const [pageProps, setPageProps] = useState({
    display: Display.RESULTS.toString(),
  });

  const handleTabChange = (event: FormEvent<EventTarget>) => {
    const { id: target } = event.currentTarget as Element;
    setPageProps({
      ...pageProps,
      display: target,
    });
  };

  return (
    <div className={styles.container}>
      <Navbar />
      <div className="row text-center">
        <SeasonsFilter />
        <EventsFilter />
        <SessionsFilter />
      </div>
      <hr />
      <div className="row mt-4">
        <SessionHeader />
      </div>
      <div className="row mt-4">
        <ResultsNavTab
          items={[Display.RESULTS, Display.LAPS, Display.CHARTS, Display.PIT_STOPS, Display.OVERTAKES]}
          active={pageProps.display}
          onClick={handleTabChange}
        />
      </div>

      <div className="row mt-4">
        {pageProps.display === Display.RESULTS && <ResultsTable />}
        {pageProps.display === Display.LAPS && (
          <LapsTable
            onClick={handleOpenModal}
            onDriverSelection={setDriverId}
            videoStartTime={startSourceTime}
            videoEndTime={endSourceTime}
          />
        )}
        {pageProps.display === Display.CHARTS && <LapsLineChart />}
        {pageProps.display === Display.CHARTS && <HeadToHeadChart />}
        {pageProps.display === Display.PIT_STOPS && (
          <PitStopList
            onClick={handleOpenModal}
            onDriverSelection={setDriverId}
            videoStartTime={startSourceTime}
            videoEndTime={endSourceTime}
          />
        )}
        {pageProps.display === Display.OVERTAKES && (
          <OvertakeList
            onClick={handleOpenModal}
            onDriverSelection={setDriverId}
            videoStartTime={startSourceTime}
            videoEndTime={endSourceTime}
          />
        )}
      </div>
      <PlayerModal
        isOpen={isOpen}
        startTime={time?.startTime}
        endTime={time?.endTime}
        source={signedLink}
        onClick={handleCloseModal}
      />
    </div>
  );
};

export default withRouter(withGlobal(ResultPage));
