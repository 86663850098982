import { useEffect, useState } from 'react';
import { usePreviousProp } from 'web-common';
import { lapService } from '../../services/lap.service';
import { LapType, UseLapsGroupedType } from './types';
import _ from 'lodash';

const useLapsGrouped = (
  sessionId?: number,
  drivers?: number | number[],
  group: string | number = 'driver_id'
): UseLapsGroupedType => {
  const [laps, setLaps] = useState<{ [key: number]: LapType[] }>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const previousProps = usePreviousProp({ sessionId, drivers, group });

  useEffect(() => {
    const getLaps = async () => {
      if (_.isEqual(previousProps, { sessionId, drivers, group })) return;

      setLoading(true);
      setError(false);
      try {
        const response = await lapService.getLaps({
          session_id: sessionId,
          driver_id: drivers,
          group: group,
        });
        setLaps(response.data as { [key: number]: LapType[] });
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getLaps();
  }, [loading, previousProps, sessionId, drivers, group]);

  return { laps, loading, error };
};

export default useLapsGrouped;
