import http from './http.services';
import { Metadata, VideoType } from '../hooks/video/types';
import * as constants from '../constants/resources.constants';

const getVideo = async (metadata: Metadata): Promise<VideoType> => {
  const { data } = await http.get(constants.VIDEO, {
    withCredentials: true,
    params: {
      ...metadata,
    },
  });
  return data;
};

export const videoService = {
  getVideo,
};
