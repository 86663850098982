import React, { FC, FormEvent } from 'react';
import './navTab.css';

interface NavTabProps {
  items: string[];
  active?: string;
  onClick?: (() => void) | ((event: FormEvent<EventTarget>) => void);
}

const NavTab: FC<NavTabProps> = ({ items, active, onClick }) => {
  const handleClick = (event: FormEvent<EventTarget>) => {
    event.preventDefault();

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <ul className="nav nav-tabs">
      {items.map((item, index) => (
        <li className="nav-item" id={item} key={index} onClick={handleClick}>
          <a href="/#" className={`nav-link ${item === active ? 'active' : ''}`}>
            {item}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default NavTab;
