import React, { FC, FormEvent } from 'react';
import { DriverType } from '../../hooks/driver/types';
import { useGlobal } from '../../store/global/useGlobal';
import Button from '../common/button';
import { colourCss } from '../../utils/colour';

import styles from './driverSelector.module.scss';

export interface DriverSelectorProps {
  drivers: DriverType[];
  selectedDrivers?: number[] | number;
  classes?: string;
  onClick: (event: FormEvent<EventTarget>) => void;
}

const DriverSelector: FC<DriverSelectorProps> = ({ drivers, selectedDrivers, classes, onClick }) => {
  const { getColour } = useGlobal();

  const isSelected = (driver: number) =>
    Array.isArray(selectedDrivers as number)
      ? (selectedDrivers as number[])?.includes(driver)
      : selectedDrivers === driver;

  return (
    <div className={`${styles.container} ${classes}`}>
      {drivers.map((driver: DriverType) => (
        <Button
          id={driver.id.toString()}
          key={driver.id}
          name={driver.code}
          onClick={onClick}
          ref={(el) => {
            if (el) {
              if (isSelected(driver.id)) {
                el.style.setProperty('background-color', colourCss(getColour(driver.team_id)), 'important');
                el.style.setProperty('color', 'white', 'important');
              } else {
                el.style.setProperty('background-color', 'transparent', 'important');
                el.style.setProperty('border-color', colourCss(getColour(driver.team_id)), 'important');
                el.style.setProperty('color', 'white', 'important');
              }
            }
          }}
          classes={`${isSelected(driver.id) ? 'btn-primary' : 'btn-outline-primary'} ${styles.button}`}
        />
      ))}
    </div>
  );
};

export default DriverSelector;
