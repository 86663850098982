import { useEffect, useState } from 'react';
import { teamService } from '../../services/team.service';
import { TeamType, UseTeamType } from './types';

const useTeam = (id: string): UseTeamType => {
  const [team, setTeam] = useState<TeamType>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getTeam = async () => {
      setLoading(true);
      setError(false);
      try {
        const response = await teamService.getTeam(id);
        if (response) {
          setTeam(response);
        }
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getTeam();
  }, [id]);

  return { team, loading, error };
};

export default useTeam;
