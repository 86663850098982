import * as types from './types';

const globalReducer = (state: types.State, action: types.Action) => {
  switch (action.type) {
    case types.SET_EVENTS: {
      return { ...state, events: action.events };
    }
    case types.SET_SESSIONS: {
      return { ...state, sessions: action.sessions };
    }
    case types.SET_DRIVERS: {
      return { ...state, drivers: action.drivers };
    }
    case types.SET_COLOURS: {
      return { ...state, colours: action.colours };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

export default globalReducer;
