import { useEffect, useState } from 'react';
import { seasonService } from '../../services/season.service';
import { SeasonType, UseSeasonsType } from './types';

const useSeasons = (): UseSeasonsType => {
  const [seasons, setSeasons] = useState<SeasonType[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getSeasons = async () => {
      setLoading(true);
      setError(false);
      try {
        const response = await seasonService.getSeasons();
        setSeasons(response.data);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getSeasons();
  }, []);

  return { seasons, loading, error };
};

export default useSeasons;
