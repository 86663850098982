import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { history } from '../../../utils/history';
import ProtectedRoute from '../protectedRoute';
import LoginPage from '../../pages/loginPage';
import LogoutPage from '../../pages/logoutPage';
import LivePage from '../../pages/livePage';
import ResultPage from '../../pages/resultPage';
import DriverPage from '../../pages/driverPage';
import TeamPage from '../../pages/teamPage';

const createRoutes = () => (
  <Router history={history}>
    <Switch>
      <Route exact path="/login" component={LoginPage} />
      <Route exact path={'/logout'} component={LogoutPage} />
      <ProtectedRoute exact={true} path="/live" component={LivePage} />
      <ProtectedRoute exact={true} path="/results" component={ResultPage} />
      <ProtectedRoute exact={true} path="/" redirect={'/results'} component={ResultPage} />
      <ProtectedRoute exact={true} path="/results/:season" component={ResultPage} />
      <ProtectedRoute exact={true} path="/results/:season/:eventId" component={ResultPage} />
      <ProtectedRoute exact={true} path="/results/:season/:eventId/:sessionId" component={ResultPage} />
      <ProtectedRoute exact={true} path="/drivers" component={DriverPage} />
      <ProtectedRoute exact={true} path="/drivers/:driverId" component={DriverPage} />
      <ProtectedRoute exact={true} path="/teams" component={TeamPage} />
      <ProtectedRoute exact={true} path="/teams/:teamId" component={TeamPage} />
    </Switch>
  </Router>
);

export default createRoutes;
