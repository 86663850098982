import React, { FC, useState, FormEvent } from 'react';
import Button from '../common/button';
import useOvertakes from '../../hooks/overtake/useOvertakes';
import DriverSelector from '../driverSelector';
import { useGlobal } from '../../store/global/useGlobal';

import styles from './overtakeList.module.scss';

export interface OvertakeListProps {
  videoStartTime: number;
  videoEndTime: number;
  onClick: (startTime: number, endTime: number) => void;
  onDriverSelection: (driverId: number | undefined) => void;
}

const OvertakeList: FC<OvertakeListProps> = ({ videoStartTime, videoEndTime, onClick, onDriverSelection }) => {
  const [selectedDriver, setSelectedDriver] = useState<number>();
  const { overtakes } = useOvertakes(selectedDriver);
  const { drivers } = useGlobal();

  const handleDriverSelection = (event: FormEvent<EventTarget>) => {
    const { id: target } = event.currentTarget as Element;
    setSelectedDriver(Number(target));
    onDriverSelection(Number(target));
  };

  return (
    <div className="col-sm-12">
      <DriverSelector
        drivers={drivers}
        selectedDrivers={selectedDriver}
        classes={styles.driverSelector}
        onClick={handleDriverSelection}
      />
      <table className="table table-dark table-bordered table-hover table-sm text-center">
        <thead>
          <tr>
            <th scope="col">Lap</th>
            <th scope="col">Turn</th>
            <th scope="col">Position</th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {overtakes.map((overtake, index) => (
            <tr key={index}>
              <td>{overtake.lap_number}</td>
              <td>{overtake.turn_number}</td>
              <td>{overtake.position}</td>
              <td>{overtake.defender_code}</td>
              <td className={styles.buttonContainer}>
                <Button
                  name={'View'}
                  type={'button'}
                  classes={`btn-primary ${styles.button}`}
                  onClick={() => onClick(overtake.start_time, overtake.end_time)}
                  disabled={overtake.start_time < videoStartTime || overtake.end_time > videoEndTime}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OvertakeList;
