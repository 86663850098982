/* Layout */
export const CHART = 'chart';
export const MARGINS = { top: 15, right: 50, bottom: 70, left: 50 };
export const GRIDSTROKEDASHARRAY = '5,2';
export const LINESTROKEARRAY = '1,2';
export const XNUMBEROFTICKS = 20;
export const YNUMBEROFTICKS = 5;
export const LABELOFFSET = 30;

/* Brush */
export const SELECTEDBOXFILL = '#00000040';
export const BRUSHREGION = 'chart';
export const BRUSHDIRECTION = 'horizontal';

/* Tooltip */
export const UNDEFINEDVALUE = '-';
