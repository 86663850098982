import http from './http.services';
import * as constants from '../constants/resources.constants';
import { EventsType, EventType } from '../hooks/event/types';

const getEvents = async (filters?: object): Promise<EventsType> => {
  const { data } = await http.get(constants.EVENTS, {
    withCredentials: true,
    params: {
      ...filters,
    },
  });
  return data;
};

const getEvent = async (id: string): Promise<EventType> => {
  const { data } = await http.get(constants.EVENT.replace('{id}', id), {
    withCredentials: true,
  });
  return data.data;
};

export const eventService = {
  getEvents,
  getEvent,
};
