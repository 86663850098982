import React, { FC, FormEvent } from 'react';
import Button from '../button';

import styles from './btnGroup.module.scss';

interface BtnGroupProps {
  items: string[];
  active: string;
  classes?: string;
  onClick?: (() => void) | ((event: FormEvent<EventTarget>) => void);
}

const BtnGroup: FC<BtnGroupProps> = ({ items, active, classes, onClick }) => {
  const handleClick = (event: FormEvent<EventTarget>) => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <div className={`${styles.btnGroup} ${classes ? classes : ''}`} role="group">
      {items.map((item, index) => (
        <Button
          id={item}
          key={index}
          name={item}
          onClick={handleClick}
          classes={`btn-secondary ${styles.button} ${item === active ? styles.active : ''}`}
        />
      ))}
    </div>
  );
};

export default BtnGroup;
