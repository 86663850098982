import React, { forwardRef } from 'react';
import { styles as stylesUtil } from 'web-common';
import { Button as ButtonTemplate } from 'web-common';
import { ButtonProps as ButtonTemplateProps } from 'web-common/src/components/button';

import styles from './button.module.scss';

const Button = forwardRef<HTMLButtonElement, ButtonTemplateProps>(({ classes = '', ...rest }, ref) => {
  return <ButtonTemplate classes={stylesUtil.mapClasses(classes, styles)} ref={ref} {...rest} />;
});

export default Button;
