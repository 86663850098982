import { useEffect, useState } from 'react';
import { videoService } from '../../services/video.service';
import { UseVideo, Event, Session, Type, Season, Driver, VideoType } from './types';

const useVideo = (event: Event, session: Session, type: Type, season: Season, driver: Driver): UseVideo => {
  const [video, setVideo] = useState<VideoType>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getVideo = async () => {
      if (!event || !session || !type || !season || !driver) return;

      setLoading(true);
      setError(false);
      setVideo(undefined);

      try {
        const response = await videoService.getVideo({ event, session, type, season, driver });
        if (response) setVideo(response);

        setError(false);
      } catch (error) {
        setError(true);
      }
      setLoading(false);
    };

    getVideo();
  }, [event, session, type, season, driver]);

  return { video, loading, error };
};

export default useVideo;
