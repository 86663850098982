import React, { FC, useState, useCallback, useEffect } from 'react';
import { Bounds } from '@visx/brush/lib/types';
import Chart, { ChartProps, Point } from '../chart';
import ChartBrush from '../chartBrush';
import ChartLayout from '../chartLayout';
import { getFilteredDataSetByXValues } from '../../../utils/chart';
import ChartTooltip from '../chartTooltip';
import AnimatedChartBar from '../animatedChartBar';

export type BarChartProps = Omit<ChartProps, 'xScale' | 'yScale'>;

const BarChart: FC<BarChartProps> = ({ data, stroke, tooltipLabels, ...props }) => {
  const [filteredData, setFilteredData] = useState<Point[]>([]);
  const [brushActive, setBrushActive] = useState(false);
  const [brushDragging, setBrushDragging] = useState(false);

  useEffect(() => setFilteredData(data as Point[]), [data]);

  const handleBrushStart = () => setBrushDragging(true);

  const handleBrushEnd = () => setBrushDragging(false);

  const handleKeyDown = ({ key }: KeyboardEvent) => key === 'Control' && setBrushActive(true);

  const handleKeyUp = ({ key }: KeyboardEvent) => key === 'Control' && setBrushActive(false);

  const handleDoubleClick = () => handleZoom(null);

  const handleZoom = useCallback(
    (domain: Bounds | null) => {
      domain?.xValues
        ? setFilteredData(getFilteredDataSetByXValues(data as Point[], domain?.xValues))
        : setFilteredData(data as Point[]);
    },
    [data]
  );

  return (
    <Chart
      data={filteredData}
      stroke={stroke}
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
      onDoubleClick={handleDoubleClick}
      xScale={'band'}
      yScale={'linear'}
      {...props}
    >
      <ChartLayout />
      {Object.entries(filteredData).map(([k, d], i) => (
        <AnimatedChartBar key={`${k}${i}`} id={`${k}${i}`} data={d} stroke={stroke?.[i]} {...props} />
      ))}
      <ChartTooltip data={filteredData} stroke={stroke} labels={tooltipLabels} />
      <ChartBrush
        enabled={brushActive || brushDragging}
        onBrushStart={handleBrushStart}
        onBrushEnd={handleBrushEnd}
        onZoom={handleZoom}
      />
    </Chart>
  );
};

export default BarChart;
