import { useEffect, useState } from 'react';
import { teamService } from '../../services/team.service';
import { TeamType, UseTeamsType } from './types';

const useTeams = (): UseTeamsType => {
  const [teams, setTeams] = useState<TeamType[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getTeams = async () => {
      setLoading(true);
      setError(false);
      try {
        const response = await teamService.getTeams();
        setTeams(response.data);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getTeams();
  }, []);

  return { teams, loading, error };
};

export default useTeams;
