import React from 'react';
import logo from './logo.png';

interface LogoProps {
  height?: string;
  width?: string;
  classes?: string;
}

const Logo = ({ height, width, classes }: LogoProps) => {
  return (
    <img
      src={logo}
      alt="RaceWatch"
      height={height ? height : '30em'}
      width={width ? width : '30em'}
      className={classes}
    />
  );
};

export default Logo;
