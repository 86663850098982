import React, { FC, FormEvent } from 'react';
import { useFilters } from '../../store/filters/useFilters';
import { useGlobal } from '../../store/global/useGlobal';
import Dropdown from '../common/dropdown';

export interface EventsFilterProps {}

const EventsFilter: FC<EventsFilterProps> = () => {
  const { event_id, setEvent } = useFilters();
  const { events } = useGlobal();

  const handleClick = (event: FormEvent<EventTarget>) => {
    const { id: target } = event.currentTarget as Element;
    const element_id = events.find((event) => event.short_name === target)?.id;
    if (element_id) {
      setEvent(element_id);
    }
  };

  return (
    <div className="col-sm-12 col-md-4 mb-2">
      <Dropdown
        title="Event"
        items={events.map((event) => {
          return event.short_name;
        })}
        active={events.find((event) => event.id === event_id)?.short_name}
        className="btn-block"
        onClick={handleClick}
      />
    </div>
  );
};

export default EventsFilter;
