import http from './http.services';
import * as constants from '../constants/resources.constants';
import { ResultsType, ResultType } from '../hooks/result/types';

const getResults = async (filters?: object): Promise<ResultsType> => {
  const { data } = await http.get(constants.RESULTS, {
    withCredentials: true,
    params: {
      ...filters,
    },
  });
  return data;
};

const getResult = async (id: string): Promise<ResultType> => {
  const { data } = await http.get(constants.RESULT.replace('{id}', id), {
    withCredentials: true,
  });
  return data.data;
};

export const resultService = {
  getResults,
  getResult,
};
