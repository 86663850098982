import { ReactNode } from 'react';

export const SET_SEASON = 'SET_SEASON';
export const SET_EVENT = 'SET_EVENT';
export const SET_SESSION = 'SET_SESSION';
export const SET_FILTER_SESSION = 'SET_FILTER_SESSION';
export const SET_TEAM = 'SET_TEAM';
export const SET_FILTER_TEAM = 'SET_FILTER_TEAM';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';

interface SetSeasonAction {
  type: typeof SET_SEASON;
  season: number | undefined;
}

interface SetEventAction {
  type: typeof SET_EVENT;
  event_id: number | undefined;
}

interface SetSessionAction {
  type: typeof SET_SESSION;
  session_id: number | undefined;
}

interface SetFilterSessionAction {
  type: typeof SET_FILTER_SESSION;
  filter_session: boolean | undefined;
}

interface SetTeamAction {
  type: typeof SET_TEAM;
  team_id: number | undefined;
}

interface SetFilterTeamAction {
  type: typeof SET_FILTER_TEAM;
  filter_team: boolean | undefined;
}

interface ClearFiltersAction {
  type: typeof CLEAR_FILTERS;
}

export type Action =
  | SetSeasonAction
  | SetEventAction
  | SetSessionAction
  | SetFilterSessionAction
  | SetTeamAction
  | SetFilterTeamAction
  | ClearFiltersAction;

export type Dispatch = (action: Action) => void;

export type State = {
  season?: number;
  event_id?: number;
  session_id?: number;
  filter_session?: boolean;
  team_id?: number;
  filter_team?: boolean;
};

export type FiltersProviderProps = { children: ReactNode };
