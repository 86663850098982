import React, { FC } from 'react';
import useResults from '../../hooks/result/useResults';
import Table from '../common/table';

export interface ResultsTableProps {}

const ResultsTable: FC<ResultsTableProps> = () => {
  const { results } = useResults();

  return (
    <div className="col-sm-12">
      <Table
        headings={['Pos', 'No.', 'Name', 'Surname', 'Team', 'Points']}
        rows={results.map((result) => {
          return [
            result.result_position,
            result.driver_number,
            result.driver_forename,
            result.driver_surname,
            result.team_name,
            result.points,
          ].map(String);
        })}
      />
    </div>
  );
};

export default ResultsTable;
