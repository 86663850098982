import React, { FC, useState, FormEvent } from 'react';
import useLaps from '../../hooks/lap/useLaps';
import Table from '../common/table';
import Button from '../common/button';
import DriverSelector from '../driverSelector';
import { useGlobal } from '../../store/global/useGlobal';
import { useFilters } from '../../store/filters/useFilters';

import styles from './lapsTable.module.scss';

export interface LapsTableProps {
  videoStartTime: number;
  videoEndTime: number;
  onDriverSelection: (driverId: number | undefined) => void;
  onClick: (startTime: number, endTime: number) => void;
}

const LapsTable: FC<LapsTableProps> = ({ videoStartTime, videoEndTime, onDriverSelection, onClick }) => {
  const [selectedDriver, setSelectedDriver] = useState<number>();
  const { drivers } = useGlobal();
  const { session_id } = useFilters();
  const { laps } = useLaps(session_id, selectedDriver);

  const handleDriverSelection = (event: FormEvent<EventTarget>) => {
    const { id: target } = event.currentTarget as Element;
    if (selectedDriver === Number(target)) {
      setSelectedDriver(undefined);
      onDriverSelection(undefined);
    } else {
      setSelectedDriver(Number(target));
      onDriverSelection(Number(target));
    }
  };

  return (
    <div className="col-sm-12">
      <DriverSelector
        drivers={drivers}
        selectedDrivers={selectedDriver}
        classes={styles.driverSelector}
        onClick={handleDriverSelection}
      />
      <Table
        tableClass={`${styles.table} ${styles.timing}`}
        headings={['Lap', 'Pos', 'Tyre', 'LapTime', 'S1', 'S2', 'S3', 'Speed', 'Run']}
        rows={laps.map((lap) => {
          return [
            lap.lap_number,
            lap.race_position,
            lap.tyre_code,
            new Date(lap.lap_time).toISOString().substr(15, 8),
            new Date(lap.sector_times[0]).toISOString().substr(17, 6),
            new Date(lap.sector_times[1]).toISOString().substr(17, 6),
            new Date(lap.sector_times[2]).toISOString().substr(17, 6),
            lap.speed_trap,
            lap.outing,
            <Button
              name={'View'}
              type={'button'}
              classes={`btn-primary ${styles.button}`}
              onClick={() => onClick(lap.start_time, lap.end_time)}
              disabled={
                !lap.start_time || !lap.end_time || lap.start_time < videoStartTime || lap.end_time > videoEndTime
              }
            />,
          ];
        })}
        rowsClass={laps.map((lap) => {
          return [
            '',
            '',
            lap.tyre_code ? styles[lap.tyre_code] : '',
            lap.lap_time_state ? styles[lap.lap_time_state] : '',
            lap.sector_time_states[0] ? styles[lap.sector_time_states[0]] : '',
            lap.sector_time_states[1] ? styles[lap.sector_time_states[1]] : '',
            lap.sector_time_states[2] ? styles[lap.sector_time_states[2]] : '',
            styles.speedtrap,
            '',
            '',
          ].map(String);
        })}
      />
    </div>
  );
};

export default LapsTable;
