import { useEffect, useState } from 'react';
import { resultService } from '../../services/result.service';
import { ResultType, UseResultsType } from './types';
import { useFiltersState } from '../../store/filters/context';

const useResults = (driverId?: number): UseResultsType => {
  const { session_id, filter_session, team_id, filter_team } = useFiltersState();
  const [results, setResults] = useState<ResultType[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getResults = async () => {
      setLoading(true);
      setError(false);
      try {
        const response = await resultService.getResults({
          ...(filter_session ? { session_id: session_id } : {}),
          ...(driverId ? { driver_id: driverId } : {}),
          ...(filter_team ? { team_id: team_id } : {}),
        });
        setResults(response.data);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getResults();
  }, [session_id, filter_session, team_id, filter_team, driverId]);

  return { results, loading, error };
};

export default useResults;
