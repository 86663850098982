import React, { CSSProperties, FC } from 'react';
import { Group } from '@visx/group';
import { Bar } from '@visx/shape';

import styles from './chartBaseBar.module.scss';

export interface ChartBaseBarProps {
  id: string;
  x: string | number;
  y?: string | number;
  width: number;
  height: number;
  stroke?: string;
  style?: CSSProperties;
  margins?: { top: number; left: number; bottom: number; right: number };
}

const ChartBaseBar: FC<ChartBaseBarProps> = ({ id, x, y, width, height, stroke, style, margins }) => {
  return (
    <Group left={margins?.left} top={margins?.top} clipPath={'url(#clipPath)'}>
      <Bar id={id} x={x} y={y} width={width} height={height} fill={stroke} className={styles.bar} style={style} />
    </Group>
  );
};

export default ChartBaseBar;
