import React, { FC, useState, useCallback, useEffect } from 'react';
import { Bounds } from '@visx/brush/lib/types';
import Chart, { ChartProps } from '../chart';
import ChartBrush from '../chartBrush';
import ChartLayout from '../chartLayout';
import ChartTooltip from '../chartTooltip';
import { getFilteredData } from '../../../utils/chart';
import AnimatedChartLine from '../animatedChartLine';

export type LineChartProps = Omit<ChartProps, 'xScale' | 'yScale'>;

const LineChart: FC<LineChartProps> = ({ data, stroke, tooltipLabels, ...props }) => {
  const [filteredData, setFilteredData] = useState(data);
  const [brushActive, setBrushActive] = useState(false);
  const [brushDragging, setBrushDragging] = useState(false);

  useEffect(() => setFilteredData(data), [data]);

  const handleBrushStart = () => setBrushDragging(true);

  const handleBrushEnd = () => setBrushDragging(false);

  const handleKeyDown = ({ key }: KeyboardEvent) => key === 'Control' && setBrushActive(true);

  const handleKeyUp = ({ key }: KeyboardEvent) => key === 'Control' && setBrushActive(false);

  const handleDoubleClick = () => handleZoom(null);

  const handleZoom = useCallback(
    (domain: Bounds | null) => {
      domain ? setFilteredData(getFilteredData(data, domain)) : setFilteredData(data);
    },
    [data]
  );

  return (
    <Chart
      data={filteredData}
      stroke={stroke}
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
      onDoubleClick={handleDoubleClick}
      xScale={'linear'}
      yScale={'linear'}
      {...props}
    >
      <ChartLayout />
      {Object.entries(filteredData).map(([k, d], i) => (
        <AnimatedChartLine key={`${k}${i}`} id={`${k}${i}`} data={d} stroke={stroke?.[i]} {...props} />
      ))}
      <ChartTooltip data={filteredData} stroke={stroke} labels={tooltipLabels} />
      <ChartBrush
        enabled={brushActive || brushDragging}
        onBrushStart={handleBrushStart}
        onBrushEnd={handleBrushEnd}
        onZoom={handleZoom}
      />
    </Chart>
  );
};

export default LineChart;
