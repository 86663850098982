import { ColourType } from '../hooks/colour/types';

const driverColour = (driver_number: number) => {
  if (driver_number === 44 || driver_number === 77) {
    return '#009999';
  } else if (driver_number === 5 || driver_number === 16) {
    return '#ff0000';
  } else if (driver_number === 23 || driver_number === 33) {
    return '#6600cc';
  } else if (driver_number === 4 || driver_number === 55) {
    return '#ff9908';
  } else if (driver_number === 3 || driver_number === 31) {
    return '#ffcc00';
  } else if (driver_number === 10 || driver_number === 26) {
    return '#808000';
  } else if (driver_number === 11 || driver_number === 18) {
    return '#ff80c7';
  } else if (driver_number === 7 || driver_number === 99) {
    return '#006eff';
  } else if (driver_number === 8 || driver_number === 20) {
    return '#6c0000';
  } else if (driver_number === 6 || driver_number === 63) {
    return '#00b0f0';
  } else {
    return '#ffffff';
  }
};

const colourCss = (colour: ColourType | undefined) => {
  return colour ? `rgb(${colour.r}, ${colour.g}, ${colour.b})` : '';
};

export { driverColour, colourCss };
