import http from './http.services';
import * as constants from '../constants/resources.constants';
import { SeasonsType, SeasonType } from '../hooks/season/types';

const getSeasons = async (filters?: object): Promise<SeasonsType> => {
  const { data } = await http.get(constants.SEASONS, {
    withCredentials: true,
    params: {
      ...filters,
    },
  });
  return data;
};

const getSeason = async (id: string): Promise<SeasonType> => {
  const { data } = await http.get(constants.SEASON.replace('{id}', id), {
    withCredentials: true,
  });
  return data.data;
};

export const seasonService = {
  getSeasons,
  getSeason,
};
