import React, { FC } from 'react';
import useLiveSession from '../../hooks/session/useLiveSession';

export interface LiveSessionHeaderProps {}

const LiveSessionHeader: FC<LiveSessionHeaderProps> = () => {
  const { session } = useLiveSession();

  if (!session) {
    return (
      <div className="col-sm-12">
        <h2>No Live Session</h2>
      </div>
    );
  }

  return (
    <div className="col-sm-12">
      <h3>{`${session.event_long_name}`}</h3>
      <h4 className="text-muted">
        {`${session?.type}`} {session.status === 'RUNNING' ? <span className="badge badge-success">Running</span> : ``}
      </h4>
    </div>
  );
};

export default LiveSessionHeader;
