import { useEffect, useState } from 'react';
import { eventService } from '../../services/event.service';
import { EventType, UseEventType } from './types';

const useEvent = (id: string): UseEventType => {
  const [event, setEvent] = useState<EventType>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getEvent = async () => {
      setLoading(true);
      setError(false);
      try {
        const response = await eventService.getEvent(id);
        if (response) {
          setEvent(response);
        }
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getEvent();
  }, [id]);

  return { event, loading, error };
};

export default useEvent;
