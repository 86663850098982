import React, { FC, FormEvent } from 'react';
import _ from 'lodash';
import ChartProvider from '../chartProvider';
import { ScaleType } from '@visx/scale';
import * as constants from '../../../constants/chart.constants';
import useChartEventHandler from '../../../hooks/useChartEventHandler';

import styles from './chart.module.scss';

export type Data = { [key: number]: Point[] } | Point[];
export type Point = { x: number; y: number };

export interface ChartProps {
  data: Data;
  xKey: string;
  yKey: string;
  xLabel: string;
  yLabel: string;
  stroke?: string[];
  tooltipLabels?: string[];
  width: number;
  height: number;
  xScale: ScaleType;
  yScale: ScaleType;
  margins?: { top: number; left: number; bottom: number; right: number };
  onKeyDown?: (() => void) | ((event: KeyboardEvent) => void);
  onKeyUp?: (() => void) | ((event: KeyboardEvent) => void);
  onClick?: (() => void) | ((event: FormEvent<EventTarget>) => void);
  onDoubleClick?: (() => void) | ((event: FormEvent<EventTarget>) => void);
}

const Chart: FC<ChartProps> = ({ width, height, children, onKeyDown, onKeyUp, onClick, onDoubleClick, ...props }) => {
  const id = _.uniqueId(constants.CHART);
  useChartEventHandler({ onKeyDown, onKeyUp });

  return (
    <ChartProvider id={id} width={width} height={height} {...props}>
      <div id={id} className={styles.container} onClick={onClick} onDoubleClick={onDoubleClick}>
        <svg width={width} height={height}>
          {children}
        </svg>
      </div>
    </ChartProvider>
  );
};

export default Chart;
