import React, { FC, useState, FormEvent } from 'react';
import Button from '../common/button';
import usePitStops from '../../hooks/pitStop/usePitStops';
import DriverSelector from '../driverSelector';
import { useGlobal } from '../../store/global/useGlobal';

import styles from './pitStopList.module.scss';

export interface PitStopListProps {
  videoStartTime: number;
  videoEndTime: number;
  onClick: (startTime: number, endTime: number) => void;
  onDriverSelection: (driverId: number | undefined) => void;
}

const PitStopList: FC<PitStopListProps> = ({ videoStartTime, videoEndTime, onClick, onDriverSelection }) => {
  const [selectedDriver, setSelectedDriver] = useState<number>();
  const { pitStops } = usePitStops(selectedDriver);
  const { drivers } = useGlobal();

  const handleDriverSelection = (event: FormEvent<EventTarget>) => {
    const { id: target } = event.currentTarget as Element;
    setSelectedDriver(Number(target));
    onDriverSelection(Number(target));
  };

  return (
    <div className="col-sm-12">
      <DriverSelector
        drivers={drivers}
        selectedDrivers={selectedDriver}
        classes={styles.driverSelector}
        onClick={handleDriverSelection}
      />
      <table className="table table-dark table-bordered table-hover table-sm text-center">
        <thead>
          <tr>
            <th scope="col">Lap</th>
            <th scope="col">Duration</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {pitStops.map((pitStop, index) => (
            <tr key={index}>
              <td>{pitStop.lap_number}</td>
              <td>{pitStop.duration}</td>
              <td className={styles.buttonContainer}>
                <Button
                  name={'View'}
                  type={'button'}
                  classes={`btn-primary ${styles.button}`}
                  onClick={() => onClick(pitStop.start_time, pitStop.end_time)}
                  disabled={pitStop.start_time < videoStartTime || pitStop.end_time > videoEndTime}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PitStopList;
