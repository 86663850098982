// Environment
export const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

// API
export const WEBSOCKET_URL = process.env.REACT_APP_API_BASE_WS;
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Auth
export const AUTH = BASE_URL + '/auth';
export const LOGIN = AUTH + '/login';
export const LOGOUT = AUTH + '/logout';
export const RENEW = AUTH + '/refresh';
export const VALIDATE_TOKEN = AUTH + '/validate';

// Drivers
export const DRIVERS = BASE_URL + '/drivers';
export const DRIVER = DRIVERS + '/{id}';

// Circuits
export const CIRCUITS = BASE_URL + '/circuits';
export const CIRCUIT = CIRCUITS + '/{id}';

// Tyres
export const TYRES = BASE_URL + '/tyres';
export const TYRE = TYRES + '/{id}';

// Events
export const EVENTS = BASE_URL + '/events';
export const EVENT = EVENTS + '/{id}';

// Sessions
export const SESSIONS = BASE_URL + '/sessions';
export const SESSION = SESSIONS + '/{id}';
export const LIVE_SESSION = BASE_URL + '/live_session';

// Teams
export const TEAMS = BASE_URL + '/teams';
export const TEAM = TEAMS + '/{id}';

// Seasons
export const SEASONS = BASE_URL + '/seasons';
export const SEASON = SEASONS + '/{id}';

// Lap Types
export const LAP_TYPES = BASE_URL + '/lap_types';
export const LAP_TYPE = LAP_TYPES + '/{id}';

// Results
export const RESULTS = BASE_URL + '/results';
export const RESULT = RESULTS + '/{id}';

// Track States
export const TRACK_STATES = BASE_URL + '/track_states';
export const TRACK_STATE = TRACK_STATES + '/{id}';

// Laps
export const LAPS = BASE_URL + '/laps';
export const LAP = LAPS + '/{id}';

// Pit Stops
export const PIT_STOPS = BASE_URL + '/pit_stops';
export const PIT_STOP = PIT_STOPS + '/{id}';

// Overtakes
export const OVERTAKES = BASE_URL + '/overtakes';
export const OVERTAKE = OVERTAKES + '/{id}';

// Timing Page One
export const TIMING_PAGE_ONE = BASE_URL + '/timing_page_one';

// Video
export const VIDEO = BASE_URL + '/video';

// Colours
export const COLOURS = BASE_URL + '/colours';
export const COLOUR = COLOURS + '/{id}';

// Head To Head
export const HEAD_TO_HEAD = BASE_URL + '/head_to_head';
