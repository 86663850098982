import { ReactNode } from 'react';
import { DriverType } from '../../hooks/driver/types';
import { EventType } from '../../hooks/event/types';
import { SessionType } from '../../hooks/session/types';
import { ColourType } from '../../hooks/colour/types';

export const SET_EVENTS = 'SET_EVENTS';
export const SET_SESSIONS = 'SET_SESSIONS';
export const SET_DRIVERS = 'SET_DRIVERS';
export const SET_COLOURS = 'SET_COLOURS';

interface SetEventsAction {
  type: typeof SET_EVENTS;
  events: EventType[];
}

interface SetSessionsAction {
  type: typeof SET_SESSIONS;
  sessions: SessionType[];
}

interface SetDriversAction {
  type: typeof SET_DRIVERS;
  drivers: DriverType[];
}

interface SetColoursAction {
  type: typeof SET_COLOURS;
  colours: ColourType[];
}

export type Action = SetEventsAction | SetSessionsAction | SetDriversAction | SetColoursAction;

export type Dispatch = (action: Action) => void;

export type State = { events: EventType[]; sessions: SessionType[]; drivers: DriverType[]; colours: ColourType[] };

export type GlobalProviderProps = { children: ReactNode };
