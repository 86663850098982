import React, { FC } from 'react';
import { AutoComplete as AutoCompleteTemplate } from 'web-common';
import { AutoCompleteProps as AutoCompleteTemplateProps } from 'web-common/src/components/autoComplete';

export type AutoCompleteProps = AutoCompleteTemplateProps;

const AutoComplete: FC<AutoCompleteProps> = ({ ...props }) => {
  return <AutoCompleteTemplate outlined={true} {...props} />;
};

export default AutoComplete;
