import React, { FC, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { useFilters } from '../../../store/filters/useFilters';
import Navbar from '../../navBar';
import DriverDetails from '../../driverDetails';
import PitStopTable from '../../pitStopTable';
import ResultsTable from '../../resultsTable';
import { useGlobal } from '../../../store/global/useGlobal';

interface DriverParams {
  driverId: string;
}

export interface DriverProps extends RouteComponentProps<DriverParams> {}

const DriverPage: FC<DriverProps> = ({ match: { params } }) => {
  const { setFilterTeam, setFilterSession } = useFilters();
  const { getDriver } = useGlobal();

  useEffect(() => {
    setFilterTeam(false);
    setFilterSession(false);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container">
      <Navbar />
      <div className="row">
        <DriverDetails driver={getDriver(Number(params.driverId))} />
      </div>
      <hr></hr>
      <div className="row">
        <div className="col-sm-12">
          <h5>Latest Results</h5>
        </div>
        <ResultsTable />
      </div>
      <hr></hr>
      <div className="row">
        <div className="col-sm-12">
          <h5>Fastest Pit Stops</h5>
        </div>
        <PitStopTable />
      </div>
    </div>
  );
};

export default withRouter(DriverPage);
