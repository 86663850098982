import React, { FC, ReactNode } from 'react';
import { useInitGlobal } from '../../../store/global/useInitGlobal';

export interface WithGlobalProps {
  children: ReactNode;
}

const withGlobal = <P extends object>(Component: React.ComponentType<P>): FC<Omit<P, 'WithGlobalProps'>> => {
  return (props) => {
    useInitGlobal();

    return <Component {...(props as P)} />;
  };
};
export default withGlobal;
