import { useEffect, useState } from 'react';
import { sessionService } from '../../services/session.service';
import { SessionType, UseSessionType } from './types';

const useLiveSession = (): UseSessionType => {
  const [session, setSession] = useState<SessionType>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getLiveSession = async () => {
      setLoading(true);
      setError(false);
      try {
        const response = await sessionService.getLiveSession();
        if (response) {
          setSession(response);
        }
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getLiveSession();
  }, []);

  return { session, loading, error };
};

export default useLiveSession;
