import { Bounds } from '@visx/brush/lib/types';
import { bisector } from 'd3-array';
import { Data, Point } from '../components/common/chart';

const getX = (d: Point) => d?.x;
const getY = (d: Point) => d?.y;

const isNan = (d: Point) => !isNaN(d.y);

const bisectData = bisector((d: any) => d.x).left;

const getFilteredData = (d: Data, b: Bounds) =>
  Object.fromEntries(Object.entries(d).map(([k, v]) => [k, getFilteredDataSet(v, b)]));

const getFilteredDataSet = (d: Point[], { x0, x1, y0, y1 }: Bounds) =>
  d.filter((d) => getX(d) > x0 && getX(d) < x1 && getY(d) > y0 && getY(d) < y1);

const getFilteredDataSetByXValues = (d: Point[], x: any[]) => d.filter((d) => x.includes(d.x));

const isDiscreteScale = (c: any) => c === 'band' || c === 'ordinal' || c === 'point';

export {
  getX,
  getY,
  isNan,
  bisectData,
  getFilteredData,
  getFilteredDataSet,
  getFilteredDataSetByXValues,
  isDiscreteScale,
};
