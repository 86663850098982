import { ReactNode } from 'react';
import { UserType, ErrorType } from '../../hooks/auth/types';

export const LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';

export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';

export const USER_RENEW_REQUEST = 'USER_RENEW_REQUEST';
export const USER_RENEW_SUCCESS = 'USER_RENEW_SUCCESS';
export const USER_RENEW_FAILURE = 'USER_RENEW_FAILURE';

export const LOG_OUT_REQUEST = 'USER_LOG_OUT_REQUEST';
export const LOG_OUT_FAILURE = 'USER_LOG_OUT_FAILURE';
export const LOG_OUT_SUCCESS = 'USER_LOG_OUT_SUCCESS';

interface LoginRequestAction {
  type: typeof LOGIN_REQUEST;
}

interface LoginFailureAction {
  type: typeof LOGIN_FAILURE;
  error: ErrorType;
}

interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  user: UserType;
}

interface LogOutRequestAction {
  type: typeof LOG_OUT_REQUEST;
}

interface LogOutFailureAction {
  type: typeof LOG_OUT_FAILURE;
  logOutError: ErrorType;
}

interface LogOutSuccessAction {
  type: typeof LOG_OUT_SUCCESS;
  user: undefined;
}

interface UserRequestAction {
  type: typeof USER_REQUEST;
}

interface UserSuccessAction {
  type: typeof USER_SUCCESS;
  user: UserType;
}

interface UserFailureAction {
  type: typeof USER_FAILURE;
  userError: ErrorType;
}

interface renewRequestAction {
  type: typeof USER_RENEW_REQUEST;
}

interface renewSuccessAction {
  type: typeof USER_RENEW_SUCCESS;
  user: UserType;
}

interface renewFailureAction {
  type: typeof USER_RENEW_FAILURE;
  renewError: ErrorType;
}

export type Action =
  | LoginRequestAction
  | LoginFailureAction
  | LoginSuccessAction
  | LogOutRequestAction
  | LogOutFailureAction
  | LogOutSuccessAction
  | UserRequestAction
  | UserSuccessAction
  | UserFailureAction
  | renewRequestAction
  | renewSuccessAction
  | renewFailureAction;

export type Dispatch = (action: Action) => void;

export type State = {
  loading: boolean;
  user?: UserType;
  error?: ErrorType;
  userError?: ErrorType;
  renewError?: ErrorType;
  logOutError?: ErrorType;
};

export type LoginProviderProps = { children: ReactNode };
