import http from './http.services';
import * as constants from '../constants/resources.constants';
import { OvertakesType, OvertakeType } from '../hooks/overtake/types';

const getOvertakes = async (filters?: object): Promise<OvertakesType> => {
  const { data } = await http.get(constants.OVERTAKES, {
    withCredentials: true,
    params: {
      ...filters,
    },
  });
  return data;
};

const getOvertake = async (id: string): Promise<OvertakeType> => {
  const { data } = await http.get(constants.OVERTAKES.replace('{id}', id), {
    withCredentials: true,
  });
  return data.data;
};

export const overtakeService = {
  getOvertakes,
  getOvertake,
};
