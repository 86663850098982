import React, { FC, useState, useEffect, useMemo } from 'react';
import BarChart from '../common/barChart';
import DriverAutoComplete from '../driverAutoComplete';
import { ParentSize } from '@visx/responsive';
import { useGlobal } from '../../store/global/useGlobal';
import * as constants from '../../constants/chart.constants';
import { useFilters } from '../../store/filters/useFilters';
import { Item } from 'web-common/dist/components/autoComplete';
import useHeadToHead from '../../hooks/headToHead/useHeadToHead';
import * as resultConstants from '../../constants/result.constants';

import styles from './headToHeadChart.module.scss';

export interface HeadToHeadChartProps {}

const HeadToHeadChart: FC<HeadToHeadChartProps> = () => {
  enum Metric {
    LapTime,
    S1,
    S2,
    S3,
  }
  const { session_id } = useFilters();
  const { drivers } = useGlobal();
  const [graphProps] = useState({
    xKey: 'x',
    yKey: 'y',
    xLabel: 'Lap Number',
    yLabel: 'Time (s)',
    yValue: Metric.LapTime,
  });

  const [driver1, setDriver1] = useState<Item | null>(null);
  const [driver2, setDriver2] = useState<Item | null>(null);

  const handleDriver1Selection = (item: Item | null) => setDriver1(item);
  const handleDriver2Selection = (item: Item | null) => setDriver2(item);

  const { headToHead } = useHeadToHead(session_id, driver1?.id as number, driver2?.id as number);

  const mappedDrivers = useMemo(() => drivers.map((driver) => ({ id: driver.id, name: driver.code })), [drivers]);

  useEffect(() => {
    setDriver1(mappedDrivers?.[0]);
    setDriver2(mappedDrivers?.[1]);
  }, [mappedDrivers]);

  return (
    <div className={styles.parentContainer}>
      <div className={styles.childContainer}>
        <div className={styles.heading}>
          <div className={styles.title}>{resultConstants.HEAD_TO_HEAD}</div>
          <div className={styles.driverAutoCompleteContainer}>
            <DriverAutoComplete
              id={resultConstants.DRIVER1}
              label={resultConstants.DRIVER1}
              items={mappedDrivers}
              selectedItem={driver1}
              classes={styles.driverAutoComplete}
              onSelect={handleDriver1Selection}
            />
            <DriverAutoComplete
              id={resultConstants.DRIVER2}
              label={resultConstants.DRIVER2}
              items={mappedDrivers}
              selectedItem={driver2}
              classes={styles.driverAutoComplete}
              onSelect={handleDriver2Selection}
            />
          </div>
        </div>
        <div className={styles.chart}>
          <ParentSize>
            {({ width, height }) => (
              <BarChart
                data={headToHead}
                xKey={graphProps.xKey}
                yKey={graphProps.yKey}
                xLabel={graphProps.xLabel}
                yLabel={graphProps.yLabel}
                margins={constants.MARGINS}
                tooltipLabels={[resultConstants.DELTA]}
                width={width}
                height={height}
              />
            )}
          </ParentSize>
        </div>
      </div>
    </div>
  );
};

export default HeadToHeadChart;
