import React, { FC, useState, FormEvent, useMemo } from 'react';
import { Data } from '../common/chart';
import LineChart from '../common/lineChart';
import BtnGroup from '../common/btnGroup';
import { ParentSize } from '@visx/responsive';
import useLapsGrouped from '../../hooks/lap/useLapsGrouped';
import { LapType } from '../../hooks/lap/types';
import { useGlobal } from '../../store/global/useGlobal';
import { colourCss } from '../../utils/colour';
import DriverAutoComplete from '../driverAutoComplete';
import { Item } from 'web-common/src/components/autoComplete';
import { useFilters } from '../../store/filters/useFilters';
import * as constants from '../../constants/chart.constants';
import * as resultConstants from '../../constants/result.constants';

import styles from './lapsLineChart.module.scss';

export interface LapsLineChartProps {}

const LapsLineChart: FC<LapsLineChartProps> = () => {
  enum Metric {
    LapTime,
    S1,
    S2,
    S3,
  }
  const { session_id } = useFilters();
  const { drivers, getColour } = useGlobal();
  const [selectedDrivers, setSelectedDrivers] = useState<Item[] | undefined>([]);
  const { laps = {} } = useLapsGrouped(
    session_id,
    selectedDrivers?.map((x) => x.id as number)
  );
  const [graphProps, setGraphProps] = useState({
    xKey: 'x',
    yKey: 'y',
    xLabel: 'Lap Number',
    yLabel: 'LapTime (s)',
    yValue: Metric.LapTime,
  });

  const handleClick = (event: FormEvent<EventTarget>) => {
    const { id: target } = event.currentTarget as Element;
    setGraphProps({
      ...graphProps,
      yLabel: `${target} (s)`,
      yValue: metricFromString(target),
    });
  };

  const mappedDrivers = useMemo(() => drivers.map((driver) => ({ id: driver.id, name: driver.code })), [drivers]);

  const handleDriverSelection = (items: Item[] | undefined) => {
    setSelectedDrivers(items);
  };

  const metricFromString = (text: string) => {
    switch (text) {
      case 'S1':
        return Metric.S1;
      case 'S2':
        return Metric.S2;
      case 'S3':
        return Metric.S3;
      default:
        return Metric.LapTime;
    }
  };

  const stringFromMetric = (metric: Metric) => {
    switch (metric) {
      case Metric.S1:
        return 'S1';
      case Metric.S2:
        return 'S2';
      case Metric.S3:
        return 'S3';
      default:
        return 'LapTime';
    }
  };

  const getDataSet = useMemo(
    () => (dataSet: LapType[]) =>
      dataSet.map((lap: LapType) => {
        let yValue = lap.lap_time;
        switch (graphProps.yValue) {
          case Metric.S1:
            yValue = lap.sector_times[0];
            break;
          case Metric.S2:
            yValue = lap.sector_times[1];
            break;
          case Metric.S3:
            yValue = lap.sector_times[2];
            break;
          default:
            yValue = lap.lap_time;
            break;
        }

        return Object({
          x: lap.lap_number,
          y: yValue ? yValue / 1000.0 : undefined,
        });
      }),
    [Metric.S1, Metric.S2, Metric.S3, graphProps.yValue]
  );

  const getData = useMemo((): Data => Object.values(laps).map((dataSet) => getDataSet(dataSet)), [laps, getDataSet]);

  const getDriverColours = useMemo(() => Object.values(laps).map((x) => colourCss(getColour(x?.[0].team_id))), [
    laps,
    getColour,
  ]);

  return (
    <div className={styles.parentContainer}>
      <div className={styles.childContainer}>
        <div className={styles.heading}>
          <div className={styles.title}>{resultConstants.LAP_TIMES}</div>
          <BtnGroup
            items={['LapTime', 'S1', 'S2', 'S3']}
            active={stringFromMetric(graphProps.yValue)}
            classes={styles.lapTimeBtnGroup}
            onClick={handleClick}
          />
          <DriverAutoComplete
            id={resultConstants.DRIVERS}
            label={resultConstants.DRIVERS}
            items={mappedDrivers}
            selectedItems={selectedDrivers}
            onSelectItems={handleDriverSelection}
            multiple={true}
            classes={styles.driverSelector}
          />
        </div>
        <div className={styles.chart}>
          <ParentSize>
            {({ width, height }) => (
              <LineChart
                data={getData}
                xKey={graphProps.xKey}
                yKey={graphProps.yKey}
                xLabel={graphProps.xLabel}
                yLabel={graphProps.yLabel}
                stroke={getDriverColours}
                tooltipLabels={drivers.map((driver) => driver.code)}
                margins={constants.MARGINS}
                width={width}
                height={height}
              />
            )}
          </ParentSize>
        </div>
      </div>
    </div>
  );
};

export default LapsLineChart;
