import React, { FC } from 'react';
import useEvent from '../../hooks/event/useEvent';
import useSession from '../../hooks/session/useSession';
import { useFilters } from '../../store/filters/useFilters';

export interface SessionHeaderProps {}

const SessionHeader: FC<SessionHeaderProps> = () => {
  const { event_id, session_id } = useFilters();
  const { event } = useEvent(event_id ? event_id.toString() : '1'); // TODO
  const { session } = useSession(session_id ? session_id.toString() : '1'); // TODO

  if (!session) {
    return <div></div>;
  }

  return (
    <div className="col-sm-12">
      <h2>{`${event?.long_name}`}</h2>
      <h4 className="text-muted">
        {`${session?.type}, ${event?.circuit_name}`}{' '}
        {session.status === 'RUNNING' ? <span className="badge badge-danger">Live</span> : ``}
      </h4>
    </div>
  );
};

export default SessionHeader;
