import React, { FC, useMemo } from 'react';
import { number } from 'web-common';
import { createScale, D3Scale } from '@visx/scale';
import { ChartProps } from '../chart';
import * as chart from '../../../utils/chart';

export type Data = { [key: number]: Dataset };
export type Dataset = { x: number; y: number; [key: string]: number }[];

export interface ChartProviderProps extends ChartProps {
  id: string;
}

export interface ChartContextProps extends Omit<ChartProviderProps, 'xScale' | 'yScale'> {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
  xMax: number;
  yMax: number;
  xScale: D3Scale<any>;
  yScale: D3Scale<any>;
}

export const ChartContext = React.createContext<Partial<ChartContextProps>>({});

const ChartProvider: FC<ChartProviderProps> = ({
  id,
  data,
  margins,
  width,
  height,
  stroke,
  tooltipLabels,
  children,
  xLabel,
  yLabel,
  xScale: xScaleConfig,
  yScale: yScaleConfig,
  ...props
}) => {
  const xMax = useMemo(() => Math.max(width - (margins?.left || 0) - (margins?.right || 0), 0), [width, margins]);
  const yMax = useMemo(() => Math.max(height - (margins?.top || 0) - (margins?.bottom || 0), 0), [height, margins]);

  const [minX, maxX] = useMemo(() => number.getMinAndMaxFrom2dArray(Object.values(data), 'x') as [number, number], [
    data,
  ]);
  const [minY, maxY] = useMemo(() => number.getMinAndMaxFrom2dArray(Object.values(data), 'y') as [number, number], [
    data,
  ]);

  const xValues = useMemo(() => Object.values(data).map((data) => data.x), [data]) as any;

  const xScale = useMemo(
    () =>
      createScale({
        domain: chart.isDiscreteScale(xScaleConfig) ? xValues : [minX, maxX],
        range: [0, xMax],
        type: xScaleConfig,
      }),
    [xMax, minX, maxX, xValues, xScaleConfig]
  );

  const yScale = useMemo(
    () =>
      createScale({
        domain: [minY, maxY],
        range: [yMax, 0],
        type: yScaleConfig,
        nice: true,
      }),
    [yMax, minY, maxY, yScaleConfig]
  );
  return (
    <ChartContext.Provider
      value={{
        id,
        data,
        width,
        height,
        xMax,
        yMax,
        minX,
        maxX,
        minY,
        maxY,
        xScale,
        yScale,
        xLabel,
        yLabel,
        margins,
        ...props,
      }}
    >
      {children}
    </ChartContext.Provider>
  );
};

export default ChartProvider;
