import http from './http.services';
import * as constants from '../constants/resources.constants';
import { UserType, LogoutType } from '../hooks/auth/types';

const login = async (username: string, password: string): Promise<UserType> => {
  const { data } = await http.post(
    constants.LOGIN,
    {
      username,
      password,
    },
    {
      withCredentials: true,
    }
  );
  return data;
};

const logout = async (): Promise<LogoutType> => {
  const { data } = await http.post(
    constants.LOGOUT,
    {},
    {
      withCredentials: true,
    }
  );
  return data;
};

const renew = async (): Promise<UserType> => {
  const { data: user } = await http.post(
    constants.RENEW,
    {},
    {
      withCredentials: true,
    }
  );
  return user;
};

const getUserToken = async (): Promise<UserType> => {
  const { data: user } = await http.get(constants.VALIDATE_TOKEN, {
    withCredentials: true,
  });
  return user;
};

export const authService = {
  login,
  logout,
  renew,
  getUserToken,
};
