import React, { FC, useState } from 'react';
import { omit } from 'lodash';
import Logo from '../common/logo';
import Input from '../common/input';
import Button from '../common/button';
import { useLogin } from '../../store/auth/useLogin';
import { validateInput } from '../../utils/validateInput';
import * as constants from '../../constants/login.constants';

import styles from './login.module.scss';

export interface LoginProps {}

const Login: FC<LoginProps> = () => {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [loginErrors, setloginErrors] = useState({});

  const { auth, login } = useLogin();

  const getError = (prop: string): string => {
    const errors: { [index: string]: any } = loginErrors;
    if (errors[prop]) {
      return errors[prop]['message'];
    }
    if (prop === constants.PASSWORD && auth.error) {
      return auth.error?.data?.errors?.[0];
    }
    return '';
  };

  const setError = (prop: string, error: { [index: string]: any }): void => {
    if (error) {
      setloginErrors({ ...loginErrors, [prop]: error[0] });
    } else {
      setloginErrors(omit(loginErrors, [prop]));
    }
  };

  const setAllErrors = (validationErrors: Array<object>) => {
    const errors: { [index: string]: any } = {};

    if (validationErrors) {
      validationErrors.forEach((error: { [index: string]: any }) => {
        const prop = error['dataPath'].split('/').pop();
        errors[prop] = [error][0];
      });

      setloginErrors(errors);
    }
  };

  const handleBlur = ({ currentTarget: input }: { currentTarget: any }): void => {
    const { id: name, value } = input;
    const error = validateInput(constants.loginSchema['properties'][name], value);
    setError(name, error);
  };

  const handleChange = ({ currentTarget: input }: { currentTarget: any }) => {
    const { id: name, value } = input;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();

    const errors = validateInput(constants.loginSchema, credentials);
    setAllErrors(errors);
    if (errors) {
      return;
    }
    const { email, password } = credentials;
    login(email, password);
  };

  return (
    <form className={styles.parentContainer}>
      <div className={styles.childContainer}>
        <div className={styles.heading}>
          <Logo height="70em" width="70em" classes={styles.logo} />
          <h1 className="h2 font-weight-normal">RaceWatch</h1>
        </div>
        <div className={styles.content}>
          <Input
            id={constants.EMAIL}
            classes={styles.input}
            value={credentials.email}
            onBlur={handleBlur}
            onChange={handleChange}
            error={getError(constants.EMAIL)}
            label={'Email address'}
            type={'email'}
          />
          <Input
            id={constants.PASSWORD}
            classes={styles.input}
            label={constants.PASSWORD}
            value={credentials.password}
            onBlur={handleBlur}
            onChange={handleChange}
            error={getError(constants.PASSWORD)}
            type={'password'}
          />
        </div>
        <Button
          name={constants.LOGIN}
          classes={`btn-outline-primary ${styles.loginBtn}`}
          type="submit"
          onClick={handleSubmit}
        />
        <p className={`${styles.copyright}`}>© 2008-2021 SBG Sports Software Limited</p>
      </div>
    </form>
  );
};

export default Login;
