import React, { createContext, useReducer, useContext } from 'react';
import { Dispatch, State, GlobalProviderProps } from './types';
import globalReducer from './reducer';

const GlobalStateContext = createContext<State | undefined>(undefined);
const GlobalDispatchContext = createContext<Dispatch | undefined>(undefined);

const GlobalProvider = ({ children }: GlobalProviderProps) => {
  const [state, dispatch] = useReducer(globalReducer, { events: [], sessions: [], drivers: [], colours: [] });
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalProvider');
  }
  return context;
};

const useGlobalDispatch = () => {
  const context = useContext(GlobalDispatchContext);
  if (context === undefined) {
    throw new Error('useGlobalDispatch must be used within a GlobalProvider');
  }
  return context;
};

export { GlobalProvider, useGlobalState, useGlobalDispatch };
