import React, { FC, FormEvent } from 'react';
import Logo from '../common/logo';
import useDrivers from '../../hooks/driver/useDrivers';
import useTeams from '../../hooks/team/useTeams';
import LiveSessionAlert from '../liveSessionAlert';

export interface NavbarProps {}

const Navbar: FC<NavbarProps> = () => {
  const { drivers } = useDrivers();
  const { teams } = useTeams();

  const handleClick = (event: FormEvent<EventTarget>) => {
    event.preventDefault();
  };

  return (
    <div className="">
      <nav className="navbar navbar-expand-lg navbar-dark mb-3">
        <div className="navbar-brand mb-0 h1">
          <Logo />
          <span className="ml-2">RaceWatch</span>
        </div>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <a className="nav-link" href="/results">
                Results
              </a>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="driverDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={handleClick}
              >
                Drivers
              </a>
              <div className="dropdown-menu" aria-labelledby="driverDropdown">
                {drivers.map((driver, index) => (
                  <a className={`dropdown-item`} key={index} id={driver.code} href={`/drivers/${driver.id}`}>
                    {driver.code}
                  </a>
                ))}
              </div>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="teamDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={handleClick}
              >
                Teams
              </a>
              <div className="dropdown-menu" aria-labelledby="teamDropdown">
                {teams.map((team, index) => (
                  <a className={`dropdown-item`} key={index} id={team.name} href={`/teams/${team.id}`}>
                    {team.name}
                  </a>
                ))}
              </div>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="/logout">
                Logout
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <LiveSessionAlert />
    </div>
  );
};

export default Navbar;
