export const EMAIL = 'email';
export const PASSWORD = 'password';

export const LOGIN = 'Log In';

const EMAIL_ERROR = 'Invalid email address';
const PASSWORD_ERROR = 'Invalid password';

export const loginSchema: any = {
  type: 'object',
  properties: {
    email: {
      type: 'string',
      format: 'email',
      minLength: 4,
      maxLength: 50,
      errorMessage: EMAIL_ERROR,
    },
    password: {
      type: 'string',
      minLength: 4,
      maxLength: 30,
      errorMessage: PASSWORD_ERROR,
    },
  },
};
