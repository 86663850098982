import http from './http.services';
import * as constants from '../constants/resources.constants';
import { TeamsType, TeamType } from '../hooks/team/types';

const getTeams = async (filters?: object): Promise<TeamsType> => {
  const { data } = await http.get(constants.TEAMS, {
    withCredentials: true,
    params: {
      ...filters,
    },
  });
  return data;
};

const getTeam = async (id: string): Promise<TeamType> => {
  const { data } = await http.get(constants.TEAM.replace('{id}', id), {
    withCredentials: true,
  });
  return data.data;
};

export const teamService = {
  getTeams,
  getTeam,
};
