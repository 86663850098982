import React, { FC, FormEvent } from 'react';
import Button from '../button';

import styles from './dropdown.module.scss';

interface DropdownProps {
  items: string[];
  active?: string;
  title?: string;
  className?: string;
  ariaLabelledBy?: string;
  onClick?: (() => void) | ((event: FormEvent<EventTarget>) => void);
}

const Dropdown: FC<DropdownProps> = ({ title, items, active, className, onClick }) => {
  const handleClick = (event: FormEvent<EventTarget>) => {
    event.preventDefault();

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <div className="dropdown">
      <Button
        id="dropdownMenuButton"
        name={active ? active : `${title}`}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        classes={`btn btn-secondary dropdown-toggle ${styles.button} ${className}`}
      />
      <div className={`${styles.dropdownMenu} dropdown-menu`} aria-labelledby="dropdownMenuButton">
        <h6 className="dropdown-header">{title}</h6>
        <div className="dropdown-divider"></div>
        {items.map((item, index) => (
          <a
            className={`dropdown-item ${item === active ? styles.active : ''}`}
            key={index}
            id={item}
            href="/#"
            onClick={handleClick}
          >
            {item}
          </a>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
