import { useCallback } from 'react';
import * as types from './types';
import { EventType } from '../../hooks/event/types';
import { SessionType } from '../../hooks/session/types';
import { DriverType } from '../../hooks/driver/types';
import { ColourType } from '../../hooks/colour/types';
import { useGlobalDispatch, useGlobalState } from './context';

const useGlobal = () => {
  const dispatch = useGlobalDispatch();
  const { events, sessions, drivers, colours } = useGlobalState();

  const getEvent = (id: number): EventType | undefined => events.find((event) => event.id === id);
  const getSession = (id: number): SessionType | undefined => sessions.find((session) => session.id === id);
  const getDriver = (id: number): DriverType | undefined => drivers.find((driver) => driver.id === id);
  const getColour = (team_id: number): ColourType | undefined => colours.find((colour) => colour.team_id === team_id);

  const setEvents = useCallback(
    (events: EventType[]) => {
      dispatch({ type: types.SET_EVENTS, events });
    },
    [dispatch]
  );

  const setSessions = useCallback(
    (sessions: SessionType[]) => {
      dispatch({ type: types.SET_SESSIONS, sessions });
    },
    [dispatch]
  );

  const setDrivers = useCallback(
    (drivers: DriverType[]) => {
      dispatch({ type: types.SET_DRIVERS, drivers });
    },
    [dispatch]
  );

  const setColours = useCallback(
    (colours: ColourType[]) => {
      dispatch({ type: types.SET_COLOURS, colours });
    },
    [dispatch]
  );

  return {
    events,
    sessions,
    drivers,
    colours,
    getEvent,
    getSession,
    getDriver,
    getColour,
    setEvents,
    setSessions,
    setDrivers,
    setColours,
  };
};

export { useGlobal };
