import React, { FC } from 'react';
import { Modal } from 'web-common';
import Player from '../common/player';
import Button from '../common/button';

import styles from './playerModal.module.scss';

export interface PlayerModalProps {
  isOpen: boolean;
  source: string;
  startTime?: number;
  endTime?: number;
  onClick: () => void;
}

const PlayerModal: FC<PlayerModalProps> = ({ isOpen, source, startTime, endTime, onClick }) => {
  return (
    <Modal isOpen={isOpen} className={styles.modal}>
      <Button
        icon={{ icon: { icon: ['fas', 'times'], type: 'FontAwesomeIcon' } }}
        tooltip={'close'}
        classes={`${styles.button} btn btn-circle-sm icon btn-secondary m-0`}
        onClick={onClick}
      />
      <Player source={source} startTime={startTime} endTime={endTime} />
    </Modal>
  );
};

export default PlayerModal;
