import React, { FC } from 'react';
import { DriverType } from '../../hooks/driver/types';
import Spinner from '../common/spinner';

export interface DriverDetailsProps {
  driver?: DriverType;
}

const DriverDetails: FC<DriverDetailsProps> = ({ driver }) => {
  if (!driver) {
    return <Spinner />;
  }

  return (
    <div className="col-sm-12">
      <h2>{`${driver?.number} ${driver?.forename} ${driver?.surname}`}</h2>
      <h4 className="text-muted">{`${driver?.team_name}`}</h4>
    </div>
  );
};

export default DriverDetails;
