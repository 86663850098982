import { useEffect, useState } from 'react';
import { lapService } from '../../services/lap.service';
import { LapType, UseLapsType } from './types';

const useLaps = (sessionId?: number, drivers?: number | number[]): UseLapsType => {
  const [laps, setLaps] = useState<LapType[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getLaps = async () => {
      if (!sessionId) return;

      setLoading(true);
      setError(false);
      try {
        const response = await lapService.getLaps({
          session_id: sessionId,
          ...(drivers ? { driver_id: drivers } : {}),
        });

        setLaps(response.data as LapType[]);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getLaps();
  }, [sessionId, drivers]);

  return { laps, loading, error };
};

export default useLaps;
