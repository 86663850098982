import { useEffect, useState } from 'react';
import { pitStopService } from '../../services/pitStop.service';
import { PitStopType, UsePitStopsType } from './types';
import { useFiltersState } from '../../store/filters/context';

const usePitStops = (driverId?: number): UsePitStopsType => {
  const { session_id, filter_session, team_id, filter_team } = useFiltersState();
  const [pitStops, setPitStops] = useState<PitStopType[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getPitStops = async () => {
      setLoading(true);
      setError(false);
      try {
        const response = await pitStopService.getPitStops({
          ...(filter_session ? { session_id: session_id } : {}),
          ...(driverId ? { driver_id: driverId } : {}),
          ...(filter_team ? { team_id: team_id } : {}),
        });
        setPitStops(response.data);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getPitStops();
  }, [session_id, filter_session, team_id, filter_team, driverId]);

  return { pitStops, loading, error };
};

export default usePitStops;
