import React, { FC } from 'react';
import ChartBaseLine, { ChartBaseLineProps } from '../chartBaseLine';
import ChartAnimatedPath from '../chartAnimatedPath';

type AnimatedChartLineProps = Omit<ChartBaseLineProps, 'path'>;

const AnimatedChartLine: FC<AnimatedChartLineProps> = ({ ...props }) => {
  return <ChartBaseLine {...props} PathComponent={ChartAnimatedPath} />;
};

export default AnimatedChartLine;
