import React, { FC } from 'react';
import useLiveSession from '../../hooks/session/useLiveSession';

interface LiveSessionAlertProps {}

const LiveSessionAlert: FC<LiveSessionAlertProps> = () => {
  const { session } = useLiveSession();

  if (!session) {
    return <div className=""></div>;
  }

  return (
    <div className="alert alert-danger alert-dismissible fade show text-center" role="alert">
      <h4>
        <span className="badge badge-danger mr-3 pulse">Live</span>
        <a href="/live" className="alert-link">
          {`${session.event_long_name}`}
        </a>
      </h4>

      <button type="button" className="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

export default LiveSessionAlert;
