import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { history } from '../../utils/history';
import { useLoginDispatch, useLoginState } from './context';
import * as types from './types';
import { UserType, ErrorType } from '../../hooks/auth/types';
import { authService } from '../../services/auth.service';

const useLogin = () => {
  const dispatch = useLoginDispatch();
  const auth = useLoginState();
  let location = useLocation();

  const login = useCallback(
    async (email: string, password: string) => {
      const request = (): types.Action => {
        return { type: types.LOGIN_REQUEST };
      };

      const success = (user: UserType): types.Action => {
        return { type: types.LOGIN_SUCCESS, user };
      };

      const failure = (error: ErrorType): types.Action => {
        return { type: types.LOGIN_FAILURE, error };
      };

      if (auth.loading) {
        return;
      }

      dispatch(request());
      authService
        .login(email, password)
        .then((user) => {
          dispatch(success(user));
          const pathName = (location.state as any)?.from || '/results';
          history.replace(pathName);
        })
        .catch((error) => dispatch(failure(error)));
    },
    [auth.loading, location.state, dispatch]
  );

  const logout = useCallback(async () => {
    const request = (): types.Action => {
      return { type: types.LOG_OUT_REQUEST };
    };

    const success = (): types.Action => {
      return { type: types.LOG_OUT_SUCCESS, user: undefined };
    };

    const failure = (logOutError: ErrorType): types.Action => {
      return { type: types.LOG_OUT_FAILURE, logOutError };
    };

    if (auth.loading) {
      return;
    }

    dispatch(request());
    await authService
      .logout()
      .then(() => {
        dispatch(success());
        history.replace('/login');
      })
      .catch((error) => dispatch(failure(error)));
  }, [auth.loading, dispatch]);

  const renew = useCallback(() => {
    const request = (): types.Action => {
      return { type: types.USER_RENEW_REQUEST };
    };

    const success = (user: UserType): types.Action => {
      return { type: types.USER_RENEW_SUCCESS, user };
    };

    const failure = (error: ErrorType): types.Action => {
      return { type: types.USER_RENEW_FAILURE, renewError: error };
    };

    dispatch(request());

    authService
      .renew()
      .then((user) => {
        dispatch(success(user));
      })
      .catch((error) => {
        dispatch(failure(error));
        logout();
      });
  }, [dispatch, logout]);

  const getUser = useCallback(async () => {
    const request = (): types.Action => {
      return { type: types.USER_REQUEST };
    };

    const success = (user: UserType): types.Action => {
      return { type: types.USER_SUCCESS, user };
    };

    const failure = (error: ErrorType): types.Action => {
      renew();
      return { type: types.USER_FAILURE, userError: error };
    };

    if (auth.loading) {
      return;
    }

    dispatch(request());

    authService
      .getUserToken()
      .then((user) => dispatch(success(user)))
      .catch((error) => dispatch(failure(error)));
  }, [auth.loading, dispatch, renew]);

  return { auth, login, logout, getUser, renew };
};

export { useLogin };
