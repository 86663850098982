import React from 'react';
import Routing from '../components/common/routing';
import { LoginProvider } from '../store/auth/context';
import { FiltersProvider } from '../store/filters/context';
import { GlobalProvider } from '../store/global/context';

import './app.scss';

const App = () => {
  return (
    <LoginProvider>
      <FiltersProvider>
        <GlobalProvider>
          <div className="App bg-dark text-white">
            <Routing />
          </div>
        </GlobalProvider>
      </FiltersProvider>
    </LoginProvider>
  );
};

export default App;
