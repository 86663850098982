import http from './http.services';
import * as constants from '../constants/resources.constants';
import { PitStopsType, PitStopType } from '../hooks/pitStop/types';

const getPitStops = async (filters?: object): Promise<PitStopsType> => {
  const { data } = await http.get(constants.PIT_STOPS, {
    withCredentials: true,
    params: {
      ...filters,
    },
  });
  return data;
};

const getPitStop = async (id: string): Promise<PitStopType> => {
  const { data } = await http.get(constants.PIT_STOPS.replace('{id}', id), {
    withCredentials: true,
  });
  return data.data;
};

export const pitStopService = {
  getPitStops,
  getPitStop,
};
