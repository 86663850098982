import React, { FC, useContext } from 'react';
import { Brush } from '@visx/brush';
import { Bounds } from '@visx/brush/lib/types';
import { Group } from '@visx/group';
import * as constants from '../../../constants/chart.constants';
import { ChartContext } from '../chartProvider';

export interface ChartBrushProps {
  enabled?: boolean;
  onBrushStart?: () => void;
  onBrushEnd?: () => void;
  onZoom?: (domain: Bounds | null) => void;
}

const ChartBrush: FC<ChartBrushProps> = ({ enabled = true, onBrushStart, onBrushEnd, onZoom }) => {
  const { xScale, yScale, xMax, yMax, margins } = useContext(ChartContext);

  const handleBrushStart = () => {
    if (onBrushStart) onBrushStart();
  };

  const handleBrushEnd = (domain: Bounds | null) => {
    if (onBrushEnd) onBrushEnd();
    if (onZoom) onZoom(domain);
  };

  return (
    <Group left={margins?.left} top={margins?.top}>
      <Brush
        xScale={xScale}
        yScale={yScale}
        resetOnEnd={true}
        width={enabled ? xMax : 0}
        height={enabled ? yMax : 0}
        brushRegion={constants.BRUSHREGION}
        brushDirection={constants.BRUSHDIRECTION}
        selectedBoxStyle={{ fill: constants.SELECTEDBOXFILL }}
        margin={{ left: margins?.left, top: margins?.top }}
        onBrushStart={handleBrushStart}
        onBrushEnd={handleBrushEnd}
      />
    </Group>
  );
};

export default ChartBrush;
