import React, { FC, useCallback, Fragment, useContext } from 'react';
import { Group } from '@visx/group';
import { LinePath } from '@visx/shape';
import { MarkerCircle } from '@visx/marker';
import { getX, getY, isNan } from '../../../utils/chart';
import { RectClipPath } from '@visx/clip-path';
import { ChartContext } from '../chartProvider';

import styles from './chartBaseLine.module.scss';

export interface ChartBaseLineProps {
  id: string;
  stroke?: string;
  data: { x: number; y: number; [key: string]: number }[];
  PathComponent?: React.FC<Omit<React.SVGProps<SVGPathElement>, 'ref'>> | 'path';
}

const ChartBaseLine: FC<ChartBaseLineProps> = ({ id, data, stroke, PathComponent = 'path' }) => {
  const { xScale, yScale, width, height, margins } = useContext(ChartContext);

  const x = useCallback((d: any) => (xScale && xScale(getX(d))) ?? 0, [xScale]);
  const y = useCallback((d: any) => (yScale && yScale(getY(d))) ?? 0, [yScale]);

  return (
    <Fragment>
      <RectClipPath id={'clipPath'} width={width} height={height}></RectClipPath>
      <Group left={margins?.left} top={margins?.top} clipPath={'url(#clipPath)'}>
        <MarkerCircle
          id={`${id}-circle`}
          className={styles.dot}
          size={1.25}
          refX={2}
          stroke={stroke}
          pointerEvents={'none'}
        />
        <LinePath x={x} y={y} defined={isNan} pointerEvents={'none'} className={styles.linePath}>
          {({ path }) => (
            <PathComponent
              stroke={stroke}
              strokeWidth={2}
              fill="transparent"
              strokeLinecap="round"
              markerStart={`url(#${`${id}-circle`})`}
              markerMid={`url(#${`${id}-circle`})`}
              markerEnd={`url(#${`${id}-circle`})`}
              d={path(data) || ''}
            />
          )}
        </LinePath>
      </Group>
    </Fragment>
  );
};

export default ChartBaseLine;
