import React, { FC, FormEvent } from 'react';
import NavTab from '../common/navTab';

export interface ResultsNavTabProps {
  items: string[];
  active: string;
  onClick?: (() => void) | ((event: FormEvent<EventTarget>) => void);
}

const ResultsNavTab: FC<ResultsNavTabProps> = ({ items, active, onClick }) => {
  const handleClick = (event: FormEvent<EventTarget>) => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <div className="col-sm-12">
      <NavTab items={items} active={active} onClick={handleClick} />
    </div>
  );
};

export default ResultsNavTab;
