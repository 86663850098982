import React, { FC, useRef, SVGProps } from 'react';
import { animated, useSpring } from 'react-spring';
import { interpolatePath } from 'd3-interpolate-path';
import debounce from 'lodash/debounce';

export interface ChartAnimatedPathProps extends Omit<SVGProps<SVGPathElement>, 'ref'> {}

const ChartAnimatedPath: FC<ChartAnimatedPathProps> = ({ d, stroke, fill, ...lineProps }) => {
  const previousD = useRef(d);
  const setPreviousD = debounce((dValue?: string) => {
    previousD.current = dValue;
  }, 50);

  const interpolator = interpolatePath(previousD.current || '', d || '');
  setPreviousD(d);

  const { t } = useSpring({
    from: { t: 0 },
    to: { t: 1 },
    reset: true,
  });
  const tweened = useSpring({ stroke, fill });

  return <animated.path d={t.to(interpolator)} stroke={tweened.stroke} fill={tweened.fill} {...lineProps} />;
};

export default ChartAnimatedPath;
