import { useEffect } from 'react';
import { url } from 'web-common';
import * as types from './types';
import { history } from '../../utils/history';
import { useFiltersDispatch, useFiltersState } from './context';

const useFilters = () => {
  const dispatch = useFiltersDispatch();
  const state = useFiltersState();

  useEffect(() => {
    history.push(`?${url.objectToUrlParams(state)}`);
  }, [state]);

  const setSeason = (season: number) => {
    dispatch({ type: types.SET_SEASON, season });
  };

  const setEvent = (event_id: number) => {
    dispatch({ type: types.SET_EVENT, event_id });
  };

  const setSession = (session_id?: number) => {
    dispatch({ type: types.SET_SESSION, session_id });
  };

  const setFilterSession = (filter_session?: boolean) => {
    dispatch({ type: types.SET_FILTER_SESSION, filter_session });
  };

  const setTeam = (team_id?: number) => {
    dispatch({ type: types.SET_TEAM, team_id });
  };

  const setFilterTeam = (filter_team?: boolean) => {
    dispatch({ type: types.SET_FILTER_TEAM, filter_team });
  };

  const clearFilters = () => {
    dispatch({ type: types.CLEAR_FILTERS });
  };

  return {
    ...state,
    setSeason,
    setEvent,
    setSession,
    setFilterSession,
    setTeam,
    setFilterTeam,
    clearFilters,
  };
};

export { useFilters };
