import React, { FC, FormEvent } from 'react';
import useSeasons from '../../hooks/season/useSeasons';
import { useFilters } from '../../store/filters/useFilters';
import Dropdown from '../common/dropdown';

export interface SeasonsFilterProps {}

const SeasonsFilter: FC<SeasonsFilterProps> = () => {
  const { seasons } = useSeasons();
  const { season, setSeason } = useFilters();

  const handleClick = (event: FormEvent<EventTarget>) => {
    const { id: target } = event.currentTarget as Element;
    setSeason(parseInt(target));
  };

  return (
    <div className="col-sm-12 col-md-4 mb-2">
      <Dropdown
        title="Season"
        items={seasons.map((season) => {
          return season.season.toString();
        })}
        active={season?.toString()}
        className="btn-block"
        onClick={handleClick}
      />
    </div>
  );
};

export default SeasonsFilter;
