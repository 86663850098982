import React, { FC } from 'react';
import { Player as MediaPlayer } from 'web-common';

import './player.module.scss';

export interface PlayerProps {
  source: string;
  startTime?: Number;
  endTime?: Number;
}

const Player: FC<PlayerProps> = ({ source, startTime, endTime }) => {
  const sourceUrl = `${source}#t=${startTime},${endTime}`;

  return <MediaPlayer id={'player'} source={{ type: 'video', sources: [{ src: sourceUrl }] }} />;
};

export default Player;
