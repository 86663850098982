import { useEffect, useState } from 'react';
import { overtakeService } from '../../services/overtake.service';
import { OvertakeType, UseOvertakesType } from './types';
import { useFiltersState } from '../../store/filters/context';

const useOvertakes = (driverId?: number): UseOvertakesType => {
  const { session_id, filter_session, team_id, filter_team } = useFiltersState();
  const [overtakes, setOvertakes] = useState<OvertakeType[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getOvertakes = async () => {
      setLoading(true);
      setError(false);
      try {
        const response = await overtakeService.getOvertakes({
          ...(filter_session ? { session_id: session_id } : {}),
          ...(driverId ? { attacker_id: driverId } : {}),
          ...(filter_team ? { team_id: team_id } : {}),
        });
        setOvertakes(response.data);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getOvertakes();
  }, [session_id, filter_session, team_id, filter_team, driverId]);

  return { overtakes, loading, error };
};

export default useOvertakes;
