import { useEffect, useState } from 'react';
import { driverService } from '../../services/driver.service';
import { DriverType, UseDriversType } from './types';

const useDrivers = (): UseDriversType => {
  const [drivers, setDrivers] = useState<DriverType[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getDrivers = async () => {
      setLoading(true);
      setError(false);
      try {
        const response = await driverService.getDrivers();
        setDrivers(response.data);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    getDrivers();
  }, []);

  return { drivers, loading, error };
};

export default useDrivers;
