import React, { FC, Fragment, useCallback, useState, useContext } from 'react';
import { createPortal } from 'react-dom';
import { Group } from '@visx/group';
import { Line } from '@visx/shape';
import { localPoint } from '@visx/event';
import { useTooltip, Tooltip } from '@visx/tooltip';
import { getX, getY, bisectData } from '../../../utils/chart';
import * as constants from '../../../constants/chart.constants';
import { ChartContext } from '../chartProvider';
import { Data, Point } from '../chart';

import styles from './chartTooltip.module.scss';

export interface ChartTooltipProps {
  data: Data;
  stroke?: string[];
  labels?: string[];
}

const ChartTooltip: FC<ChartTooltipProps> = ({ data, stroke, labels }) => {
  const { id, xLabel, yLabel, xScale, yScale, xMax, yMax, margins } = useContext(ChartContext);

  const portalContainer = id && document.getElementById(id);

  const { showTooltip, hideTooltip, tooltipData, tooltipLeft = 0, tooltipTop = 0 } = useTooltip<
    (Point | undefined)[]
  >();

  const x = useCallback((d: any) => (xScale && xScale(getX(d))) ?? 0, [xScale]);
  const y = useCallback((d: Point) => (yScale && yScale(getY(d))) ?? 0, [yScale]);

  const [currentX, setCurrentX] = useState(0);

  const handleTooltip = useCallback(
    (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
      const { x: point } = localPoint(event) || { x: 0 };
      const xOffset = Math.max(point - (margins?.left || 0), 0);
      let x0: any;

      if (xScale && 'invert' in xScale) {
        x0 = xScale.invert(xOffset);
      } else {
        var eachBand = xScale && 'step' in xScale ? xScale?.step() : 0;
        var index = Math.round(xOffset / eachBand);
        x0 = xScale?.domain()[index];
      }

      if (!x0) return;

      const arr = Object.values(data).flat();
      const idx = bisectData(arr, x0, 1);
      const d0 = arr[idx - 1];
      const d1 = arr[idx];
      let d = d0;

      if (d1 && getX(d1)) d = x0.valueOf() - getX(d0).valueOf() > getX(d1).valueOf() - x0.valueOf() ? d1 : d0;

      setCurrentX(d?.x);

      showTooltip({
        tooltipData: Array.isArray(data?.[0])
          ? Object.values(data).map((x) => x.find((x: any) => x.x === d.x))
          : [(data as Point[]).find((x) => x.x === d.x)],
        tooltipLeft: x(d),
        tooltipTop: y(d),
      });
    },
    [data, xScale, margins, showTooltip, x, y]
  );

  return (
    <Fragment>
      <Group left={margins?.left} top={margins?.top}>
        <rect
          width={xMax}
          height={yMax}
          opacity={0}
          onTouchMove={handleTooltip}
          onMouseMove={handleTooltip}
          onTouchEnd={hideTooltip}
          onMouseLeave={hideTooltip}
        />

        {tooltipData?.length && (
          <g>
            <Line
              from={{ x: tooltipLeft, y: 0 }}
              to={{ x: tooltipLeft, y: yMax }}
              stroke={'white'}
              strokeWidth={1}
              pointerEvents={'none'}
            />
            {tooltipData.map(
              (tooltip, i) =>
                tooltip?.x && (
                  <circle
                    key={`marker-${i}`}
                    cx={x(tooltip)}
                    cy={y(tooltip)}
                    r={4}
                    fill={stroke?.[i]}
                    stroke="white"
                    strokeWidth={2}
                  />
                )
            )}
          </g>
        )}
      </Group>
      {portalContainer &&
        tooltipData?.length &&
        createPortal(
          <Tooltip
            key={Math.random()}
            top={tooltipTop - 10}
            left={tooltipLeft - 55}
            applyPositionStyle={true}
            className={styles.tooltipLabel}
            unstyled={true}
          >
            <div>
              <div className={styles.xLabel}>{`${xLabel}: ${currentX}`}</div>
              {tooltipData.map((tooltip, i) => (
                <div key={`tooltip-${i}`} className={styles.yLabel}>
                  <div className={styles.indicator} style={{ backgroundColor: stroke?.[i] }} />
                  <div className={styles.yLabel}>{`${labels?.[i] || yLabel}: ${
                    tooltip?.y || constants.UNDEFINEDVALUE
                  }`}</div>
                </div>
              ))}
            </div>
          </Tooltip>,
          portalContainer
        )}
    </Fragment>
  );
};

export default ChartTooltip;
