import http from './http.services';
import * as constants from '../constants/resources.constants';
import { SessionsType, SessionType } from '../hooks/session/types';

const getSessions = async (filters?: object): Promise<SessionsType> => {
  const { data } = await http.get(constants.SESSIONS, {
    withCredentials: true,
    params: {
      ...filters,
    },
  });
  return data;
};

const getSession = async (id: string): Promise<SessionType> => {
  const { data } = await http.get(constants.SESSION.replace('{id}', id), {
    withCredentials: true,
  });
  return data.data;
};

const getLiveSession = async (): Promise<SessionType> => {
  const { data } = await http.get(constants.LIVE_SESSION, {
    withCredentials: true,
  });
  return data.data;
};

export const sessionService = {
  getSessions,
  getSession,
  getLiveSession,
};
