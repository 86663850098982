import http from './http.services';
import * as constants from '../constants/resources.constants';
import { HeadToHeadType } from '../hooks/headToHead/types';

const getHeadToHead = async (filters?: object): Promise<HeadToHeadType> => {
  const { data } = await http.get(constants.HEAD_TO_HEAD, {
    withCredentials: true,
    params: {
      ...filters,
    },
  });
  return data;
};

export const headToHeadService = {
  getHeadToHead,
};
